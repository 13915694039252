import {
  Button,
  Col,
  Divider,
  List,
  notification,
  Row,
  Spin,
  Typography,
} from "antd";
import Item from "antd/es/list/Item";
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { CardFI } from "../../components/CardFI";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import { useApi } from "../../hooks/useApi";
import { PagamentoMP } from "../../components/PagamentoMP";

export const Pedido = () => {
  const api = useApi();
  const auth = useContext(AuthContext);
  const [load, setLoad] = useState(false);
  const [messageLoad, setMessageLoad] = useState("");
  const [pedidos, setPedidos] = useState([]);
  const [openPagamento, setOpenPagamento] = useState(false);
  const [codepreference, setCodepreference] = useState("");
  const [celular, setCelular] = useState("");
  const [produtonome, setProdutonome] = useState("");
  const [produtoquantidade, setProdutoquantidade] = useState("");
  const [produtovalor, setProdutovalor] = useState("");

  const loadPedidos = async () => {
    setMessageLoad("Buscando seus pedidos, aguarde...");
    setLoad(true);
    await api.loadPedidoFiliado().then((res) => {
      setPedidos(res);
    });
    setMessageLoad("");
    setLoad(false);
  };

  const onPayment = (pedido: any) => {
    const item = pedido["itens"];
    setCelular(pedido["celular_pessoa"]);
    setProdutonome("Cotas UnLocke");
    setProdutoquantidade(item[0]["quantidade"]);
    setProdutovalor(item[0]["valor"]);
    setCodepreference(pedido["code_preference"]);
    setOpenPagamento(true);
  };

  const closePagamento = (open: boolean) => {
    setOpenPagamento(false);
  };

  const returnPagamento = async (retorno: any) => {
    setOpenPagamento(false);
  };

  const excluirPedido = async (id: string) => {
    setMessageLoad("Excluindo o pedido, aguarde...");
    setLoad(true);
    await api.cancelPedido(id).then((res) => {
      notification.open({ message: res.message });
      loadPedidos();
    });
    setMessageLoad("");
    setLoad(false);
  };

  useLayoutEffect(() => {
    loadPedidos();
  }, []);

  return (
    <>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          padding: 10,
          color: "#fff",
          fontSize: 18,
          fontFamily: "Roboto",
          fontWeight: 300,
        }}
      >
        PEDIDOS DE COMPRAS DE COTAS
      </Row>
      <Divider style={{ margin: 5 }} />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          fontSize: 28,
          color: "#fff",
          paddingTop: 0,
          height: "64vh",
          overflowY: "scroll",
        }}
      >
        <Spin spinning={load} tip={messageLoad}>
          <List
            size="large"
            // header={<div style={{ display: 'flex', justifyContent: 'center', padding: 10, fontSize: 28, color: '#fcfcfc', backgroundColor: '#0000009e', borderRadius: 10 }}>Lista de Pedidos</div>}
            //bordered
            style={{ display: "flex", justifyContent: "center", width: "95vw" }}
            dataSource={pedidos!}
            renderItem={(item) => (
              <List.Item>
                <Row
                  style={{
                    width: "100%",
                    backgroundColor: "#ffffff",
                    borderRadius: 5,
                    padding: 5,
                  }}
                >
                  <Col
                    style={{
                      paddingInline: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                    }}
                  >
                    <Row style={{ fontSize: 10, color: "#2c2c2c" }}>Código</Row>
                    <Row style={{ fontSize: 12, color: "#0f0f0f" }}>
                      <strong>{item["id"]}</strong>
                    </Row>
                  </Col>
                  <Col
                    style={{
                      paddingInline: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                    }}
                  >
                    <Row style={{ fontSize: 10, color: "#2c2c2c" }}>
                      Situação
                    </Row>
                    <Row style={{ fontSize: 12, color: "#0f0f0f" }}>
                      <strong>{item["status"]}</strong>
                    </Row>
                  </Col>
                  <Col
                    style={{
                      paddingInline: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                    }}
                  >
                    <Row style={{ fontSize: 10, color: "#2c2c2c" }}>Data</Row>
                    <Row style={{ fontSize: 12, color: "#0f0f0f" }}>
                      <strong>{item["data_pedido"]}</strong>
                    </Row>
                  </Col>
                  {item["status"] == "Pago" ? (
                    <>
                      <Col
                        style={{
                          paddingInline: 10,
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "column",
                        }}
                      >
                        <Row style={{ fontSize: 10, color: "#2c2c2c" }}>
                          Pagamento
                        </Row>
                        <Row style={{ fontSize: 12, color: "#0f0f0f" }}>
                          <strong>{item["data_pagamento"]}</strong>
                        </Row>
                      </Col>
                      <Col
                        style={{
                          paddingInline: 10,
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "column",
                        }}
                      >
                        <Row style={{ fontSize: 10, color: "#2c2c2c" }}>
                          Forma de Pagamento
                        </Row>
                        <Row style={{ fontSize: 12, color: "#0f0f0f" }}>
                          <strong>{item["tipo_pagamento"]}</strong>
                        </Row>
                      </Col>
                    </>
                  ) : item["status"] == "Aberto" ? (
                    <>
                      <Col
                        style={{
                          paddingInline: 10,
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "column",
                        }}
                      >
                        <Row style={{ fontSize: 10, color: "#2c2c2c" }}>
                          Observação
                        </Row>
                        <Row style={{ fontSize: 12, color: "#0f0f0f" }}>
                          Se já fez o pagamento aguarde a confirmação, caso
                          contrário faça o pagamento.
                        </Row>
                      </Col>
                      <Col
                        style={{
                          paddingInline: 10,
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "column",
                        }}
                      >
                        <Row style={{ fontSize: 10, color: "#2c2c2c" }}>
                          Ação
                        </Row>
                        <Row style={{ fontSize: 12, color: "#0f0f0f" }}>
                          <Col>
                            <Button type="link" onClick={() => onPayment(item)}>
                              Pagamento
                            </Button>
                          </Col>
                          <Col>
                            <Button
                              danger
                              type="link"
                              onClick={() => excluirPedido(item["id"])}
                            >
                              Excluir
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </>
                  ) : (
                    <>Ainda estamos aguardando a confirmação do pagamento.</>
                  )}
                  {/* <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        Saldo: <strong>R${item.saldo}</strong>
                                        Cota acima: {item.cota_acima}
                                    </Col>
                                    <Col span={24}>
                                        Código Convite: <strong>{item.codigo_unico}</strong>
                                    </Col> */}
                </Row>
              </List.Item>
            )}
          />
        </Spin>
      </div>

      <PagamentoMP
        title="Pagamento do Pedido"
        open={openPagamento}
        code_preference={codepreference}
        nome={auth.user!.name}
        cpf={auth.user!.cpf}
        celular={celular}
        email={auth.user!.email}
        nome_produto={produtonome}
        valor_produto={produtovalor}
        quantidade_produto={produtoquantidade}
        onChange={returnPagamento}
        onClose={closePagamento}
      />
    </>
  );
};
