import { CloseCircleOutlined } from "@ant-design/icons"
import { Card } from "antd"
import styles from "./styles.module.css";

export const CardFI = ({id, display, children, title, size} : {id: string, display: string, children : JSX.Element, title: string, size: string}) => {
    return (
        // <Draggable>
            <Card
                id={id}
                title={title}
                //className={styles.CardFIStyle}
                //extra={<a href='../sistema'><CloseCircleOutlined style={{ fontSize:32, color: '#fff' }} /></a>}
                style={{ display: display, width: size, height: 'auto', border: 'none', borderRadius: "8px 8px 0px 0px", boxShadow : "5px 5px 12px #2E2E2E",  }}
                headStyle={{ backgroundColor: 'rgb(30, 34, 151)', border: 'none', paddingTop: 0, borderRadius: "7px 7px 0px 0px", color : "#fff"}}
            >
                <div className={styles.CardMark}>
                    {children}
                </div>
            </Card>
        // </Draggable>
    )
}