import { Button, Col, Divider, Form, Input, Row, Space, Table, notification } from "antd";
import { CardFI } from "../../components/CardFI";
import { useApi } from "../../hooks/useApi";
import { ClearOutlined, DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import internal from "stream";

export const CategoriaCurso = () => {
    
    const api = useApi();
    const [form] = Form.useForm();
    const [categorias, setCategorias] = useState([]);

    const columns = [
        {
            title: "Código",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Nome",
            dataIndex: "nome",
            key: "nome",
        },
        {
            title: "Ação",
            dataIndex: "acao",
            key: "acao",
            render: (_:any, record: any) => (
                <Space>
                    <Button onClick={(e) => editCategoria(record)}><EditOutlined /></Button>
                    <Button><DeleteOutlined /></Button>
                </Space>
            ),
        }
    ];

    const saveCategoria = async (values:any) => {
        await api.saveCategoriaCurso(values).then((res) => {
            notification.info({message: 'Resposta do servidor!', description: res.message});
            if(res.data){
                form.resetFields();
                getCategorias();
            }
        });
    }

    const getCategorias = async () => {
        await api.comboCategoriaCurso().then((res) => {
            setCategorias(res);
        });
    }

    const editCategoria = (categoria: any) => {
        form.setFieldsValue({
            id: categoria.id,
            nome: categoria.nome,
        });
    }

    useEffect(() => {
        getCategorias();
    }, [])

    return (
        <>
            <CardFI title="Cadastro de Categorias de Curso" id="cadastro_categoria" size="70%" display="block">
                <>
                    <Form
                        form={form}
                        onFinish={saveCategoria}
                        layout="vertical"
                    >
                        <Row style={{ width: '99%' }}>
                            <Col span={4} style={{ paddingRight: 5 }}>
                                <Form.Item label="Código" name="id">
                                    <Input readOnly />
                                </Form.Item>
                            </Col>
                            <Col span={20}>
                                <Space.Compact>
                                    <Form.Item label="Nome da Categoria" name="nome" required>
                                        <Input/>
                                    </Form.Item>
                                    <Button htmlType="submit" style={{ marginTop: 31 }}><PlusOutlined /></Button>
                                    <Button onClick={(e) => form.resetFields()} style={{ marginTop: 31 }}><ClearOutlined /></Button>
                                </Space.Compact>
                            </Col>
                        </Row>
                        

                    </Form>
                    <Divider />
                    <Table columns={columns} dataSource={categorias} />
                </>
            </CardFI>
        </>
    );
}