import { SendOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Divider, Form, Input, notification, Row, Space, Spin } from "antd";
import { Footer } from "antd/lib/layout/layout";
import { useEffect, useState } from "react";
import { NavBar } from "../../components/NavBar";
import { useApi } from "../../hooks/useApi";
import styles from "./styles.module.css";

export const Contato = () => {

    const api = useApi();
    const [form] = Form.useForm();
    const [screenW, setScreenW] = useState(0);
    const [mini, setMin] = useState(450);

    const [load, setLoad] = useState(false);
    const [msgLoad, setMsgLoad] = useState('');

    const onFinish = async (values: any) => {
        setMsgLoad('Entregando seu email, aguarde...');
        setLoad(true);
        await api.contatoMessage(values).then((res) => {
            notification.open({message: res.message});
            form.resetFields();
        });
        setMsgLoad('');
        setLoad(false);
    }

    useEffect(() => {
        setScreenW(window.innerWidth);
        //loadComboUF();
    }, []);

    return (
        <>  
            <NavBar />
            <Spin spinning={load} tip={msgLoad} >
                <Row style={{ backgroundColor: '#fff', width: '100vw', display: 'flex', justifyContent: 'center', padding: 10, height: '100vh' }}>
                    <Col style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', width: 400, padding: 10  }}>
                        <Row style={{ display: 'flex', justifyContent: 'center' }}><img style={{ width: 400 }} src={'/imgs/duvida.gif'} /></Row>
                        <Row style={{ paddingTop: 20, fontSize: 18, fontWeight: 100, color: '#525252' }}>
                        Não hesite em entrar em contato conosco se você tiver qualquer dúvida ou precisar de ajuda. Estamos à disposição para ajudá-lo.
                        </Row>
                    </Col>
                    <Col style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', width: 400, padding: 10  }}>
                        <span style={{ fontFamily: 'Roboto', fontWeight: 700, fontSize: 28 }}>Fale Conosco</span>
                        <Divider style={{ marginTop: 2 }}/>
                        <Form
                            name="formLogin"
                            onFinish={onFinish}
                            initialValues={{ remember: true }}
                            labelCol={{ span: 6 }}
                            form={form}
                        >
                            <Form.Item label={'Assunto'} name={'assunto'} rules={[{required:true}]} >
                                <Input />
                            </Form.Item>
                            <Form.Item label={'Seu email'} name={'email'} rules={[{required: true, type: 'email'}]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={'Mensagem'} name={'mensagem'} rules={[{required: true}]}>
                                <Input.TextArea rows={5}/>
                            </Form.Item>                                    
                            <Row style={{ display: 'flex', justifyContent: 'center' }}>
                                <Form.Item>
                                    <Button
                                        htmlType="submit" 
                                        style={{ backgroundColor: '#012970', color: '#fff' }}
                                    >
                                        <SendOutlined />&nbsp;Enviar
                                    </Button>
                                    {/* <Divider></Divider>
                                    Ou <NavLink to="/cadastrar/0" className={styles.planoContratarSel}>Cadastre-se agora!</NavLink> */}
                                </Form.Item>
                            </Row>                                   
                        </Form>
                    </Col>
                </Row>
            </Spin>         
        </>
    )
}