import { message, notification } from "antd";
import { useContext, useEffect, useState } from "react";
import { useApi } from "../../hooks/useApi";
import { Cota } from "../../types/Cota";
import { Filiacao } from "../../types/Filiacao";
import { User } from "../../types/User";
import { AuthContext } from "./AuthContext";

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [user, setUser] = useState<User | null>(null);
  const [filiacao, setFiliacao] = useState<Filiacao | null>(null);
  const [cotas, setCotas] = useState<Cota[] | null>([]);
  const [cota, setCota] = useState<Cota | null>(null);
  const api = useApi();
  const nivel = [
    "Malkuth",
    "Yesod",
    "Hod",
    "Netzah",
    "Tiphereth",
    "Geburah",
    "Hesed",
    "Binah",
    "Hochmah",
    "Kether",
  ];

  useEffect(() => {
    if (
      localStorage.getItem("authToken") != undefined &&
      localStorage.getItem("authToken") != null
    ) {
      validateToken();
    }
  }, []);

  const validateToken = async () => {
    let storageDate = localStorage.getItem("authToken");
    if (storageDate) {
      await api.validateToken(storageDate).then((res) => {
        const data = res.data;
        if (data.user) {
          setUser(data.user);
          const params = data.parameters;
          setFiliacao(params.filiacao);
          setCotas(params.cotas);
          setToken(data.token);
        }
      });
    }
  };

  const signin = async (cpf: string, password: string) => {
    const response = await api.signin(cpf, password);
    const data = response.data;
    if (data) {
      if (data.user && data.token) {
        const params = data.parameters;
        setUser(data.user);
        setToken(data.token);
        setFiliacao(params.filiacao);
        setCotas(params.cotas);
        return data;
      }
    }
    return null;
  };

  const signout = async () => {
    await api.logout(localStorage.getItem("authToken")!);
    setUser(null);
    setFiliacao(null);
    setCotas(null);
    setToken("");
    //caso nao funcione faz assim reload
    //window.location.href = window.location.href;
  };

  const setToken = (token: string) => {
    localStorage.setItem("authToken", token);
  };

  return (
    <AuthContext.Provider
      value={{ user, filiacao, cotas, cota, nivel, signin, signout }}
    >
      {children}
    </AuthContext.Provider>
  );
};
