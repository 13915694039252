import { Button, Card, Divider, Form, Modal, Row, Spin } from "antd"
import { useContext, useEffect, useState } from "react";
import { useMercadopago } from "react-sdk-mercadopago";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import { useApi } from "../../hooks/useApi";

type Props = {
    title: string,
    code_preference: string,
    nome: string,
    cpf: string,
    email: string,
    celular: string,
    nome_produto: string,
    valor_produto: string,
    quantidade_produto: string,
    onChange: (retorno: any) => void,
    onClose: (open: boolean) => void,
    open: boolean
}

export const PagamentoMP = ({title, code_preference, nome, cpf, email, celular, nome_produto, valor_produto, quantidade_produto, onChange, onClose, open = false} : Props) => {

    const api = useApi();
    const auth = useContext(AuthContext);
    const [displayCad, setDisplayCad] = useState('block');
    const [displayList, setDisplayList] = useState('none');
    const [activeKey, setActiveKey] = useState('1');
    const [formPF] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [messageLoad, setMessageLoad] = useState("Carregando...");

    const mercadopago = useMercadopago.v2(
        "TEST-9aae3a82-60ea-4312-b78f-3dd8bf4ab6a2",
        {
          locale: "pt-BR"
        }
      );

    const [rendered, setRendered] = useState(false);

    useEffect(() => {
        //if (!rendered) {
          mercadopago?.checkout({
            preference: {
              id: code_preference
            },
            render: {
              container: ".cho-container",
              label: "Pagar"
            },
            
          });
          setRendered(true);
        //}
      }, [code_preference]);

    return (
        <>
        <Modal
                title={title}
                centered
                open={open}
                onOk={()=>onClose(false)}
                onCancel={()=>onClose(false)}
                width={'50%'}
                footer
            > 
                <Spin tip={messageLoad} spinning={loading}>           
                    <Card>
                        <Row>
                            Dados do Usuário
                        </Row>
                        <Row>
                            Nome: {nome}
                        </Row>
                        <Row>
                            Cpf: {cpf}
                        </Row>
                        <Row>
                            Email: {email}
                        </Row>
                        <Row>
                            Celular: {celular}
                        </Row>
                        <Divider>Dados da Compra</Divider>
                        <Row>
                            Produto: {nome_produto}
                        </Row>
                        <Row>
                            Quantidade: {quantidade_produto}
                        </Row>
                        <Row>
                            Valor: R${valor_produto}
                        </Row>
                        {/* <Row>
                            Código Mercado Pago: {code_preference}
                        </Row> */}
                        <Row style={{ marginTop: 20 }}>
                            <div className="cho-container" />
                        </Row>
                    </Card>
                </Spin>
            </Modal>            
        </>
    )
}