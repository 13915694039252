import { AppstoreOutlined, HomeOutlined, MailOutlined, QuestionOutlined, RocketOutlined, SecurityScanOutlined, SettingOutlined, UserAddOutlined } from "@ant-design/icons";
import { Button, Menu, Row } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom"
import "./styles.css";

export const NavBar = () => {

    const [isNavExpanded, setIsNavExpanded] = useState(false);

    const [colorChange, setColorchange] = useState(false);
    const changeNavbarColor = () =>{
        if(window.scrollY >= 80){
        setColorchange(true);
        }
        else{
        setColorchange(false);
        }
    };
    window.addEventListener('scroll', changeNavbarColor);

    return (
        <nav className={!colorChange ? "navigation" : "navigation_scroll"}>
            <Row align="middle" style={{ marginBottom: 10 }}>
                <a href="/" className="brand-name" style={{ display: 'flex', justifyContent: 'center' }}>
                    <img height={42} src={'/imgs/unlocke_icon_2.png'} /><br/>&nbsp;<span style={{ marginTop: 10, fontSize: 32, fontFamily: 'Inter', fontWeight: 800 }}>UnLocke</span>
                </a>
            </Row>
            
            <button
                className="hamburger"
                onClick={() => {
                setIsNavExpanded(!isNavExpanded);
                }}
            >
                {/* icon from Heroicons.com */}
                <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="white"
                >
                <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                    clipRule="evenodd"
                />
                </svg>
            </button>
            <div
                className={
                isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
                }
            >                
                <ul>
                    <li>
                        <a href="/"><Button><HomeOutlined /> Início</Button></a>
                    </li> 
                    <li>
                        <a href="/about"><Button><RocketOutlined /> Clube Exclusívo</Button></a>
                    </li>
                    {/* <li>
                        <a href="/about"><Button><QuestionOutlined /> Sobre</Button></a>
                    </li> */}
                    <li>
                        <a href="/contact"><Button><MailOutlined /> Contato</Button></a>
                    </li>
                    <li>
                        <a href="/cadastroSite"><Button><UserAddOutlined /> Cadastro</Button></a>
                    </li>
                    <li>
                        <a href="/login"><Button style={{ backgroundColor: '#1f014e', color: '#fff' }} ><SecurityScanOutlined /> Área do Filiado</Button></a>
                    </li>
                </ul>
            </div>
        </nav>
    );
}