import { ReloadOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Row, Space } from "antd";
import { useState } from "react";
import { useApi } from "../../hooks/useApi";

export const Parametros = () => {
  const api = useApi();
  const [urlWebhook, setUrlWebhook] = useState(
    `${process.env.REACT_APP_API}/notificacaoPagamentoEfi`
  );

  const atualizaWebHook = async () => {
    await api.setWebHook(urlWebhook).then((res) => {
      console.log("webhook configurado com sucesso", res);
    });
  };

  return (
    <>
      <Card title={"Parametros do sistema"}>
        <Row>
          <Col span={12}>
            <Form.Item label={"Url de webhook"}>
              <Space.Compact style={{ width: "100%" }}>
                <Input
                  value={urlWebhook}
                  onChange={(e) => setUrlWebhook(e.target.value)}
                />
                <Button
                  icon={<ReloadOutlined />}
                  onClick={(e) => atualizaWebHook()}
                />
              </Space.Compact>
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Parametros;
