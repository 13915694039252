// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getToken, getMessaging, isSupported } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC_DUznT5AogwthJKfW4CWlK_0gCOku7Sc",
  authDomain: "unlocke-74145.firebaseapp.com",
  projectId: "unlocke-74145",
  storageBucket: "unlocke-74145.appspot.com",
  messagingSenderId: "1013125542776",
  appId: "1:1013125542776:web:cec97ec2e1557ca7c44e8d",
  measurementId: "G-ZNMQDGC0ZP",
};

const UrlFirebaseConfig = new URLSearchParams({
  apiKey: "AIzaSyC_DUznT5AogwthJKfW4CWlK_0gCOku7Sc",
  authDomain: "unlocke-74145.firebaseapp.com",
  projectId: "unlocke-74145",
  storageBucket: "unlocke-74145.appspot.com",
  messagingSenderId: "1013125542776",
  appId: "1:1013125542776:web:cec97ec2e1557ca7c44e8d",
  measurementId: "G-ZNMQDGC0ZP",
});

const swUrl = `${
  window.location.hostname == "localhost"
    ? "http://localhost:3000"
    : "http://" + window.location.hostname
}/firebase-messaging-sw.js?${UrlFirebaseConfig}`;

console.log("URL CONFIG FB: ", swUrl);

export const firebaseApp = initializeApp(firebaseConfig);

export const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(firebaseApp);
    }
    console.log("Firebase não é suportado neste navegador");
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
})();

export const getOrRegisterServiceWorker = () => {
  if (
    "serviceWorker" in navigator &&
    typeof window.navigator.serviceWorker !== "undefined"
  ) {
    return window.navigator.serviceWorker
      .getRegistration("/firebase-push-notification-scope")
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register(
          "/firebase-messaging-sw.js",
          {
            scope: "/firebase-push-notification-scope",
          }
        );
      });
  }
  throw new Error("O seu navegador não suporta o serviço de notificações!");
};

export const getFirebaseToken = async () => {
  try {
    const messagingResolve = await messaging;
    if (messagingResolve) {
      return getOrRegisterServiceWorker().then((serviceWorkerRegistration) => {
        return Promise.resolve(
          getToken(messagingResolve, {
            vapidKey: process.env.REACT_APP_FB_PUBLIC_KEY,
            serviceWorkerRegistration,
          })
        );
      });
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }
};
