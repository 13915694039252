import { createContext } from "react";
import { Cota } from "../../types/Cota";
import { Filiacao } from "../../types/Filiacao";
import { User } from "../../types/User";

export type AuthContextType = {
  user: User | null;
  filiacao: Filiacao | null;
  cotas: Array<Cota> | null;
  cota: Cota | null;
  nivel: Array<String> | null;
  signin: (cpf: string, password: string) => Promise<any>;
  signout: () => void;
};

export const AuthContext = createContext<AuthContextType>(null!);
