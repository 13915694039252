import {
  LockOutlined,
  MailOutlined,
  SecurityScanFilled,
  UserAddOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  notification,
  Row,
  Space,
  Spin,
} from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { Footer } from "antd/es/layout/layout";
import { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { NavBar } from "../../components/NavBar";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import { useApi } from "../../hooks/useApi";
import styles from "./styles.module.css";

export const Login = () => {
  const api = useApi();
  const contextLogin = useContext(AuthContext);
  const navigate = useNavigate();
  const [formLogin] = Form.useForm();
  const [lembrarme, setLembrarme] = useState(false);
  const [loadOpen, setLoadOpen] = useState(false);
  const [msgLoad, setMsgLoad] = useState("");
  const [isModalOpenTermoUso, setIsModalOpenTermoUso] = useState(false);
  const [isModalOpenPrivacidade, setIsModalOpenPrivacidade] = useState(false);

  const showModalTermoUso = () => {
    setIsModalOpenTermoUso(true);
  };

  const handleOkTermoUso = () => {
    setIsModalOpenTermoUso(false);
  };

  const handleCancelTermoUso = () => {
    setIsModalOpenTermoUso(false);
  };

  const showModalPrivacidade = () => {
    setIsModalOpenPrivacidade(true);
  };

  const handleOkPrivacidade = () => {
    setIsModalOpenPrivacidade(false);
  };

  const handleCancelPrivacidade = () => {
    setIsModalOpenPrivacidade(false);
  };

  //Function
  const onRemember = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      localStorage.setItem("relembreme", "sim");
    } else {
      localStorage.setItem("relembreme", "nao");
    }
    setLembrarme(e.target.checked);
  };

  const onLogin = async (values: any) => {
    setMsgLoad("Autenticando usuário, aguarde...");
    setLoadOpen(true);
    if (values.email && values.password) {
      await contextLogin
        .signin(values.email, values.password)
        .then((res) => {
          if (res) {
            if (lembrarme) {
              localStorage.setItem("relembreme", "sim");
              localStorage.setItem("email", values.email);
              localStorage.setItem("key", values.password);
            } else {
              localStorage.setItem("relembreme", "não");
              localStorage.setItem("email", "");
              localStorage.setItem("key", "");
            }
            contextLogin.user = res.user;
            const parameters = res.parameters;
            contextLogin.filiacao = parameters.filiacao;
            contextLogin.cotas = parameters.cotas;
            notification.success({
              message: "Acesso realizado com sucesso!",
              description:
                "Seja bem vindo " + contextLogin.user?.name + ". Carpe diem!",
            });
            navigate("../sistema");
          } else {
            notification.error({ message: "Usuário ou senha inválidos!" });
            localStorage.setItem("relembreme", "não");
            localStorage.setItem("email", "");
            localStorage.setItem("key", "");
            contextLogin.user = null;
            contextLogin.filiacao = null;
            contextLogin.cotas = null;
          }
        })
        .catch((erro) => {
          notification.error({
            message: "Erro de autenticação",
            description: "Hoveram problemas na sua tentativa de login.",
          });
          localStorage.setItem("relembreme", "não");
          localStorage.setItem("email", "");
          localStorage.setItem("key", "");
          contextLogin.user = null;
          contextLogin.filiacao = null;
          contextLogin.cotas = null;
        });
    } else {
      notification.error({ message: "Usuário e senha são obrigatórios!" });
    }
    setMsgLoad("");
    setLoadOpen(false);
  };

  const solicitarNovaSenha = async () => {
    const email = formLogin.getFieldValue("email");
    if (email) {
      setMsgLoad("Criando nova senha e enviando para o seu email.");
      setLoadOpen(true);
      await api.requestPassword(email).then((res) => {
        notification.info({ message: res.message });
      });
      setMsgLoad("");
      setLoadOpen(false);
    } else {
      notification.error({
        message: "Informe seu email para enviarmos a senha.",
      });
    }
  };

  useEffect(() => {
    const localstorage = localStorage.getItem("relembreme");
    if (localstorage == "sim") {
      setLembrarme(true);
    } else {
      setLembrarme(false);
    }
    //se user set login auth
    if (contextLogin.user != null) {
      //<Navigate to="/sistema"/>;
      message.info("Usuário continua autenticado!");
      navigate("../sistema");
    } else {
      if (lembrarme) {
        formLogin.setFieldValue("email", localStorage.getItem("email"));
        formLogin.setFieldValue("key", localStorage.getItem("password"));
      }
    }
  }, [contextLogin.user]);

  return (
    <>
      <Spin spinning={loadOpen} tip={msgLoad}>
        <div className={styles.container}>
          <NavBar />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              height: "99vh",
            }}
          >
            <Row
              align="middle"
              style={{
                width: "100vw",
                marginTop: 50,
                marginBottom: -30,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <a href="/" style={{ display: "flex", justifyContent: "center" }}>
                <img
                  className="floating"
                  height={150}
                  src={"/imgs/unlocke_icon_2.png"}
                />
              </a>
            </Row>
            <div style={{ width: 300, padding: 10 }}>
              <Form
                form={formLogin}
                onFinish={onLogin}
                style={{
                  backgroundColor: "#ffffff83",
                  paddingInline: 10,
                  paddingBlock: 3,
                  borderRadius: 10,
                }}
              >
                <span
                  style={{
                    marginTop: 10,
                    fontSize: 32,
                    fontFamily: "Inter",
                    fontWeight: 800,
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                >
                  UnLocke
                </span>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: 5,
                    fontFamily: "Roboto",
                    fontWeight: 600,
                    marginTop: -10,
                  }}
                >
                  Acesso ao Sistema
                </Row>
                <Divider style={{ marginTop: -5 }} />
                <Form.Item
                  name={"email"}
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Email é obrigatório!",
                    },
                  ]}
                >
                  <Input
                    type="email"
                    prefix={<MailOutlined />}
                    placeholder={"Email do usuário"}
                  />
                </Form.Item>
                <Form.Item
                  name={"password"}
                  rules={[
                    { required: true, message: "A senha é obrigatória!" },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    placeholder={"Senha do usuário"}
                  />
                </Form.Item>
                <Form.Item>
                  <Space
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginTop: -5,
                    }}
                  >
                    <Button type="primary" htmlType="submit">
                      Acessar
                    </Button>
                    <Button danger>Limpar</Button>
                  </Space>
                </Form.Item>
              </Form>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  backgroundColor: "#ffffff83",
                  padding: 10,
                  borderRadius: 10,
                  borderTop: "1px dashed gray",
                }}
              >
                <Col>
                  <Checkbox onChange={onRemember} defaultChecked={lembrarme}>
                    Lembrar-me
                  </Checkbox>
                </Col>
                <Col>
                  <a
                    style={{ textDecoration: "none", color: "#000" }}
                    href="/cadastroSite"
                  >
                    <UserAddOutlined /> Cadastre-se
                  </a>
                </Col>
              </Row>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  backgroundColor: "#ffffff83",
                  padding: 10,
                  borderRadius: 10,
                  borderTop: "1px dashed gray",
                }}
              >
                <Button
                  type="link"
                  size="small"
                  style={{
                    color: "#000",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    solicitarNovaSenha();
                  }}
                  icon={<UserAddOutlined />}
                >
                  Esqueci a senha
                </Button>
              </Row>
            </div>
          </div>

          <Footer
            style={{
              position: "fixed",
              bottom: -15,
              right: 5,
              textAlign: "right",
              backgroundColor: "transparent",
              color: "#ffffff",
              fontSize: 12,
              width: "100vw",
            }}
          >
            UnLocke ©2023 - Version: {process.env.REACT_APP_VERSION}
          </Footer>
        </div>
      </Spin>
    </>
  );
};
