import { Card, Col, Row, Typography } from "antd";
import { Footer } from "antd/es/layout/layout";
import { NavBar } from "../../components/NavBar";
import styles from "./styles.module.css";

const { Title } = Typography;

export const Compra = () => {
    return (
        <>
            <NavBar />
            <div className={styles.container}>
                <Col span={24} style={{ paddingTop: 20 }}>
                    <Row style={{ display: 'flex', justifyContent: 'center', width: '100vw' }} align={'middle'}>
                        <Title level={3}>Falta pouco para o novo sol raiar na sua vida.</Title>
                    </Row>
                    <Row style={{ display: 'flex', justifyContent: 'center', width: '100vw' }} align={'middle'}>
                        <Title level={5}>Se prepare para viver o extraordinário</Title>
                    </Row>
                    <Row style={{ display: 'flex', justifyContent: 'center', width: '100vw' }} align={'middle'}>
                        <Card title={'Dados da Compra'} style={{ width: '50%' }}>

                        </Card>
                    </Row>
                </Col>
                <Footer style={{ position: 'fixed', bottom: 0, textAlign: 'right', backgroundColor: 'transparent', color: '#252525', fontSize: 12, width: '100vw',  }}>Unlocke ©2021 Created by RDCA Tecnologia - Version: {process.env.REACT_APP_VERSION}</Footer>
            </div>
        </>
    )
}