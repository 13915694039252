import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";

export const RequireAuth = ({children, levelReq} : {children : JSX.Element, levelReq: number}) => {
    
    const auth = useContext(AuthContext);
    
    if(auth.user){
        if(levelReq > auth!.user!.level){
            //pagina avisando que nao tem permissao.
            return <Navigate to="acessoindevido" />
        }
        return children;
    }
    //validar pois ta jogando para fora
    // if(!auth.user!.isadm){
    //     if(!auth.fazenda_sel){
    //         return <FazendaSel />;
    //     }
    // } 
    return <Navigate to="/login"/>;
}