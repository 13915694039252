import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  Card,
  List,
  Rate,
  Row,
  Tag,
  Tooltip,
  message,
} from "antd";
import Meta from "antd/lib/card/Meta";
import { Curso } from "../../types/Curso";
import styles from "./styles.module.css";

export const CursoCard = ({
  id,
  nome,
  descricao,
  capa,
  rate,
  categorias_nome,
}: Curso) => {
  const [messageApi, contextHolder] = message.useMessage();

  const informacao = (texto: string) => {
    messageApi.open({
      type: "success",
      content: texto,
      className: "custom-class",
      style: {
        marginTop: "30vh",
      },
    });
  };

  return (
    <>
      <div className={styles.container}>
        {/*<Badge.Ribbon
          text={
            <Button
              type="link"
              size="small"
              style={{ color: "#ffffff" }}
              onClick={() => message.info(descricao)}
            >
              Descrição
            </Button>
          }
          color="red"
        >*/}
        <Card
          //hoverable
          style={{ width: "240px", borderRadius: 10 }}
          cover={<img style={{ borderRadius: 10 }} alt={nome} src={capa} />}
        >
          <Tooltip title={descricao}>
            <Meta title={nome} />
          </Tooltip>
          <Row
            style={{
              width: "100%",
              fontSize: 9,
              display: "flex",
              justifyContent: "center",
            }}
          >
            {categorias_nome}
          </Row>
          <Rate disabled value={Math.random() * (5 - 2) + 3} />
        </Card>
        {/*</Badge.Ribbon>*/}
      </div>
    </>
  );
};
