import {
  EyeInvisibleFilled,
  EyeTwoTone,
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Popover,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import { MaskedInput } from "antd-mask-input";
import { useForm } from "antd/es/form/Form";
import { Footer } from "antd/lib/layout/layout";
import { useEffect, useState } from "react";
import ReactPasswordChecklist from "react-password-checklist";
import { useNavigate, useParams } from "react-router-dom";
import { NavBar } from "../../components/NavBar";
import { useApi } from "../../hooks/useApi";
import { Privacidade } from "../Privacidade";
import { TermoUso } from "../TermoUso";
import "./styles.module.css";
import { PagamentoMP } from "../../components/PagamentoMP";

export const CadastroSite = () => {
  const apiCadastro = useApi();
  const [convite, setConvite] = useState<string | undefined>();
  const { envite } = useParams();
  const navigate = useNavigate();
  const [ufs, setUfs] = useState([{ key: "", value: "" }]);
  const [cidades, setCidades] = useState([{ key: "", value: "" }]);
  const [screenW, setScreenW] = useState(0);
  const [mini, setMin] = useState(450);
  const [codepreference, setCodepreference] = useState("");
  const [nome, setNome] = useState("");
  const [cpf, setCpf] = useState("");
  const [email, setEmail] = useState("");
  const [celular, setCelular] = useState("");
  const [produtonome, setProdutonome] = useState("");
  const [produtoquantidade, setProdutoquantidade] = useState("");
  const [produtovalor, setProdutovalor] = useState("");
  const [openPagamento, setOpenPagamento] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageLoad, setMessageLoad] = useState("Carregando...");
  const [qtdCota, setQtdCota] = useState<number | null>(1);
  const [valorPedido, setValorPedido] = useState<string | null>("19.90");
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [senhaValida, setSenhaValida] = useState(false);
  const [isModalOpenTermoUso, setIsModalOpenTermoUso] = useState(false);
  const [isModalOpenPrivacidade, setIsModalOpenPrivacidade] = useState(false);
  const [change, setChange] = useState([]);
  const [form] = Form.useForm();

  const showModalTermoUso = () => {
    setIsModalOpenTermoUso(true);
  };

  const handleOkTermoUso = () => {
    setIsModalOpenTermoUso(false);
  };

  const handleCancelTermoUso = () => {
    setIsModalOpenTermoUso(false);
  };

  const showModalPrivacidade = () => {
    setIsModalOpenPrivacidade(true);
  };

  const handleOkPrivacidade = () => {
    setIsModalOpenPrivacidade(false);
  };

  const handleCancelPrivacidade = () => {
    setIsModalOpenPrivacidade(false);
  };

  const onFinish = async (values: any) => {
    values.quantidade_cota = qtdCota;
    values.password = password;
    values.c_password = passwordAgain;
    if (envite != undefined) {
      values.codigo_anfitriao = envite;
    }
    setMessageLoad("Gerando seu pedido, aguarde...");
    setLoading(true);
    values.fcm_token = localStorage.getItem("fcm_token");
    await apiCadastro
      .subscribe(
        values.id_filiacao,
        values.nome,
        values.cpf,
        values.sexo,
        values.email,
        values.celular,
        values.password,
        values.c_password,
        values.aceite_termos,
        values.codigo_anfitriao,
        values.quantidade_cota,
        values.fcm_token
      )
      .then((res) => {
        notification.info({
          message: res.message,
        });
        if (res.data) {
          const data = res.data;
          setCodepreference(data.pedido.code_preference);
          setChange(data.preference);
          setNome(data.pessoa.nome);
          setCpf(data.pessoa.cpf);
          setEmail(data.pessoa.email);
          setCelular(data.pessoa.celular);
          setProdutonome("Convite da Plataforma Clube UnLocke");
          setProdutoquantidade(data.pedido.quantidade_cota);
          setProdutovalor(data.pedido.total_pedido);
          setOpenPagamento(true);
          form.resetFields();
        }
      })
      .catch((erro) => {
        notification.error({
          message: erro.response.data.message,
          //btn: (<Button type="primary" size="small" onClick={() => {openCompra(res.pessoa.id);}}>Filiar-se</Button>)
        });
      });
    setLoading(false);
    setMessageLoad("");
  };

  const onPayment = () => {
    setOpenPagamento(true);
  };

  const closePagamento = (open: boolean) => {
    setOpenPagamento(false);
  };

  const returnPagamento = async (retorno: any) => {
    console.log(retorno);
    setOpenPagamento(false);
  };

  useEffect(() => {
    setScreenW(window.innerWidth);
    //loadComboUF();
    //console.log("Variavel envite: ", envite);
  }, []);

  useEffect(() => {
    const valor: number = 19.9;
    if (qtdCota) {
      const total: number = qtdCota * valor;
      setValorPedido(parseFloat(total.toString()).toFixed(2));
    } else {
      notification.error({
        message: "A quantidade de convites não pode ser nula.",
      });
    }
  }, [qtdCota]);

  const ListValidPassword = (
    <div>
      <ReactPasswordChecklist
        rules={[
          "minLength",
          "specialChar",
          "number",
          "capital",
          "lowercase",
          "match",
        ]}
        minLength={8}
        value={password}
        valueAgain={passwordAgain}
        onChange={(isValid) => {
          setSenhaValida(isValid);
        }}
        messages={{
          minLength: "A senha deve ter no mínimo 8 caracteres.",
          specialChar: "A senha deve ter um caracter especial.",
          number: "A senha deve ter um número",
          capital: "A senha deve ter uma letra maiúscula.",
          lowercase: "A senha deve ter uma letra minúscula.",
          match: "As senhas devem ser iguais.",
        }}
      />
    </div>
  );

  return (
    <>
      <NavBar />
      <div className={"container"}>
        <Spin tip={messageLoad} spinning={loading}>
          <Row
            style={{
              backgroundColor: "#ffffff78",
              width: "100vw",
              display: "flex",
              justifyContent: "center",
              paddingInline: 10,
              height: "100vh",
            }}
          >
            <Col
              id="colImg"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                width: 400,
                paddingInline: 10,
              }}
            >
              <Row style={{ display: "flex", justifyContent: "center" }}>
                <img
                  style={{
                    width: 300,
                    borderRadius: "0px 30px 0px 30px",
                    border: "2px solid gold",
                  }}
                  src={"/imgs/reception.jpeg"}
                />
              </Row>
              {/* <Row style={{ paddingTop: 20, fontSize: 18, fontWeight: 100, color: '#525252' }}>
                                Você está a um passo do seu crescimento exponencial.
                            </Row> */}
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                width: 400,
                paddingInline: 10,
              }}
            >
              <Row
                style={{
                  fontSize: 24,
                  fontFamily: "Inter",
                  fontWeight: 800,
                  color: "#012970",
                  paddingTop: 20,
                  borderBottom: "1px solid silver",
                }}
              >
                Cadastro
              </Row>
              <Row style={{ paddingTop: 10, fontSize: 18, fontWeight: 500 }}>
                <Form
                  name="formCadastro"
                  onFinish={onFinish}
                  labelCol={{ span: 8 }}
                  style={{ width: "100%" }}
                  form={form}
                >
                  <Form.Item hidden name={"id_filiacao"} initialValue={1}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    style={{ marginTop: -5 }}
                    label={"Nome"}
                    name={"nome"}
                    rules={[{ required: true, message: "Nome é obrigatório!" }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    style={{ marginTop: -15 }}
                    label={"Cpf"}
                    name={"cpf"}
                    rules={[{ required: true, message: "Cpf é obrigatório!" }]}
                  >
                    <MaskedInput
                      placeholder={"CPF do usuário"}
                      id="cpf"
                      mask={"000.000.000-00"}
                      required={true}
                      style={{ width: 200 }}
                    />
                  </Form.Item>

                  {/* <Form.Item style={{ marginTop: -10 }} label={'Sexo'} name={'sexo'} rules={[{required: true}]}>
                                        <Select
                                            options={[
                                                {value: 'Masculino', label: 'Masculino'},
                                                {value: 'Feminino', label: 'Feminino'}
                                            ]}
                                            style={{ width: 200 }}
                                        />
                                    </Form.Item> */}

                  <Form.Item
                    style={{ marginTop: -15 }}
                    label={"Email"}
                    name={"email"}
                    rules={[
                      {
                        required: true,
                        type: "email",
                        message: "Email é obrigatório!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    style={{ marginTop: -15 }}
                    label={"Celular"}
                    name={"celular"}
                    rules={[
                      {
                        required: true,
                        message: "Númedo de celular é obrigatório!",
                      },
                    ]}
                  >
                    <MaskedInput
                      placeholder={"Celular do usuário"}
                      id="celular"
                      mask={"(00) 00000-0000"}
                      required={true}
                      style={{ width: 200 }}
                    />
                  </Form.Item>

                  {/* <Form.Item label="UF" name="estado_id" rules={[{ required: true, message: 'A UF do endereço é obrigatória!' }]}>
                                        <Select className={styles.styleInput} onChange={loadComboCity}>
                                        {ufs.map((option, index) => {
                                            return (
                                                <Select.Option key={index} value={option.value}>{option.key}</Select.Option>
                                            )
                                        })}
                                        </Select>                    
                                    </Form.Item> 

                                    <Form.Item label="Cidade" name="cidade_id" rules={[{ required: true, message: 'A cidade do endereço é obrigatória!' }]}>
                                        <Select>
                                        {cidades.map((option, index) => {
                                            return (
                                                <Select.Option key={index} value={option.value}>{option.key}</Select.Option>
                                            )
                                        })}
                                        </Select>                    
                                    </Form.Item>                                  */}

                  <Form.Item
                    name="password"
                    label="Senha"
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: 'Por favor informe uma senha com no mínimo 8 caracteres!',
                    //         len: 8,
                    //     },
                    // ]}
                    hasFeedback
                    style={{ marginTop: -15 }}
                  >
                    <Popover
                      placement="bottom"
                      content={ListValidPassword}
                      title={"Validação"}
                    >
                      <Input.Password
                        style={{ width: 200 }}
                        onFocus={(e) => ListValidPassword}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Popover>
                  </Form.Item>

                  <Form.Item
                    style={{ marginTop: -15 }}
                    name="c_password"
                    label="Confirme a senha"
                    dependencies={["password"]}
                    hasFeedback
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: 'Por favor confirme sua senha!',
                    //     },
                    //     ({ getFieldValue }) => ({
                    //         validator(_, value) {
                    //             if (!value || getFieldValue('password') === value) {
                    //                 return Promise.resolve();
                    //             }
                    //             return Promise.reject(new Error('As duas senhas que você informou não batem!'));
                    //         },
                    //     }),
                    // ]}
                  >
                    <Popover
                      placement="bottom"
                      content={ListValidPassword}
                      title={"Validação"}
                    >
                      <Input.Password
                        style={{ width: 200 }}
                        onFocus={(e) => ListValidPassword}
                        onChange={(e) => setPasswordAgain(e.target.value)}
                      />
                    </Popover>
                  </Form.Item>
                  {envite != undefined ? (
                    <Form.Item
                      style={{ marginTop: -15 }}
                      label={"Convite"}
                      name={"codigo_anfitriao"}
                    >
                      <Input style={{ width: 200 }} value={envite} readOnly />
                      &nbsp;
                    </Form.Item>
                  ) : (
                    <Form.Item
                      style={{ marginTop: -15 }}
                      label={"Convite"}
                      name={"codigo_anfitriao"}
                      rules={[
                        {
                          required: true,
                          message:
                            "É obrigatório um convite para se filiar. Caso não tenha, pode solicitar!",
                        },
                      ]}
                    >
                      <Input
                        style={{ width: 200 }}
                        onInput={(e) => {
                          e.currentTarget.value =
                            e.currentTarget.value.toUpperCase();
                        }}
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    style={{ marginTop: -15 }}
                    label={"Convite(s)"}
                    name={"quantidade_cota"}
                    tooltip={
                      "O convite é uma unidade para sua bonificação. Você pode ter vários convites e convidar seus amigos e distribui-los por esses convites, montando assim grupos. A quantidade máxima de convites para compra é 100 unidades."
                    }
                  >
                    <Input.Group compact>
                      <InputNumber
                        name="quantidade_cota"
                        style={{ width: 50 }}
                        min={1}
                        max={100}
                        defaultValue={1}
                        onChange={setQtdCota}
                        value={qtdCota}
                      />
                      <Button>{`R\$ ${valorPedido!}`}</Button>
                    </Input.Group>
                  </Form.Item>

                  <Form.Item
                    style={{ marginTop: -15 }}
                    name="aceite_termos"
                    valuePropName="checked"
                    wrapperCol={{ offset: 4 }}
                    rules={[
                      {
                        required: true,
                        message:
                          "É preciso aceitar o termo de uso e a política de privacidade da Unlocke para continuar.!",
                      },
                    ]}
                  >
                    <Checkbox>
                      <span style={{ color: "#000000" }}>
                        Aceito o{" "}
                        <a href="#" onClick={showModalTermoUso}>
                          Termo de uso
                        </a>{" "}
                        e{" "}
                        <a href="#" onClick={showModalPrivacidade}>
                          Política de Privacidade
                        </a>
                      </span>
                    </Checkbox>
                  </Form.Item>

                  <Form.Item
                    wrapperCol={{ offset: 8 }}
                    style={{ marginTop: -15 }}
                  >
                    {codepreference == "" ? (
                      <Button type="primary" htmlType="submit">
                        Cadastrar-se
                      </Button>
                    ) : (
                      <Button type="primary" onClick={onPayment}>
                        Pagar
                      </Button>
                    )}
                  </Form.Item>
                </Form>
              </Row>
            </Col>
          </Row>
        </Spin>
        <PagamentoMP
          title="Pagamento do Pedido"
          open={openPagamento}
          code_preference={codepreference}
          nome={nome}
          cpf={cpf}
          celular={celular}
          email={email}
          nome_produto={produtonome}
          valor_produto={produtovalor}
          quantidade_produto={produtoquantidade}
          onChange={returnPagamento}
          onClose={closePagamento}
        />
        <Modal
          footer
          open={isModalOpenTermoUso}
          onOk={handleOkTermoUso}
          onCancel={handleCancelTermoUso}
        >
          <TermoUso />
        </Modal>
        <Modal
          footer
          open={isModalOpenPrivacidade}
          onOk={handleOkPrivacidade}
          onCancel={handleCancelPrivacidade}
        >
          <Privacidade />
        </Modal>
        <Footer
          style={{
            position: "fixed",
            bottom: 5,
            right: 5,
            textAlign: "right",
            backgroundColor: "transparent",
            color: "#252525",
            fontSize: 12,
            width: "100vw",
          }}
        >
          Unlocke ©2021 Created by RDCA Tecnologia - Version:{" "}
          {process.env.REACT_APP_VERSION}
        </Footer>
      </div>
    </>
  );
};
