import { NavBar } from "../../components/NavBar";
import style from "./styles.module.css";
import { CSSTransition } from "react-transition-group";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Divider, Row } from "antd";
import { useNavigate } from "react-router-dom";

export const Sobre = () => {
  const videoRef = useRef(document.createElement("video"));
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    //setShow(true);
    videoRef.current.play();
  }, []);

  return (
    <>
      <NavBar />
      <Row
        style={{
          backgroundColor: "#000",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          padding: 10,
          height: "100vh",
        }}
      >
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            padding: 10,
          }}
        >
          <Row
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            {/*<img style={{ width: 400 }} src={'/imgs/cafe.png'} />*/}
            <video
              ref={videoRef}
              src={
                "https://unlockeapi.rdca.com.br/public/storage/videos_site/Apresentação%20-%20Unlocke%20‐%20Feito%20com%20o%20Clipchamp.mp4"
              }
              controls
              controlsList="nodownload"
              style={{
                height: "50vh",
                borderRadius: 10,
                backgroundColor: "transparent",
              }}
            />
          </Row>
          <Row
            style={{
              paddingTop: 20,
              fontSize: 18,
              fontWeight: 300,
              color: "#fff",
            }}
          >
            Participe do nosso programa de indicações e ganhe cashback, enquanto
            faz com que seus amigos aproveitem nossos cursos de alto nível.
          </Row>
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: 400,
            padding: 10,
          }}
        >
          <Row
            style={{
              fontSize: 38,
              fontFamily: "Inter",
              fontWeight: 800,
              color: "#3a71d1",
              paddingTop: 20,
            }}
          >
            Você terá acesso a uma série de vantagens exclusivas.
          </Row>
          <Row
            style={{
              paddingTop: 20,
              fontSize: 22,
              fontWeight: 100,
              color: "#fff",
            }}
          >
            Alcance seu máximo potencial com nossos cursos de alta qualidade.
            Adquira novos conhecimentos e habilidades valiosas e compartilhe sua
            experiência com amigos e colegas.
          </Row>
        </Col>
      </Row>
      <Row
        style={{
          backgroundColor: "#cfcfcf",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          padding: 10,
          height: "90vh",
        }}
      >
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: 400,
            padding: 10,
          }}
        >
          <Row
            style={{
              fontSize: 38,
              fontFamily: "Inter",
              fontWeight: 800,
              color: "#012970",
              paddingTop: 20,
            }}
          >
            Cursos sem restrição.
          </Row>
          <Row style={{ paddingTop: 20, fontSize: 22, fontWeight: 100 }}>
            Ao se tornar nosso afiliado você terá acesso a todo o acervo de
            cursos, e-books e audio-books que fazem parte de nossa biblioteca.
            <br />
            Nossos cursos são selecionados para trazer maior aproveitamento em
            suas experiências, maximilizando seu desempenho tando profissional
            quanto pessoal.
          </Row>
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: 400,
            padding: 10,
          }}
        >
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <img style={{ width: 400 }} src={"/imgs/cafe.png"} />
            {/*<img style={{ width: 400 }} src={"/imgs/planing.png"} />*/}
          </Row>
          <Row
            style={{
              paddingTop: 20,
              fontSize: 18,
              fontWeight: 100,
              color: "#525252",
            }}
          >
            Participe do nosso programa de indicações e ganhe cashback enquanto
            faz com que seus amigos aproveitem nossos cursos de alto nível.
          </Row>
        </Col>
      </Row>
      <Row
        style={{
          backgroundColor: "#fff",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          padding: 10,
          height: "90vh",
        }}
      >
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: 400,
            padding: 10,
          }}
        >
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <img style={{ height: 400 }} src={"/imgs/sucessso.png"} />
          </Row>
          <Row
            style={{
              paddingTop: 20,
              fontSize: 18,
              fontWeight: 100,
              color: "#525252",
            }}
          >
            Ajude seus amigos a desfrutarem de nossos cursos e tenha um retorno
            por isso. Indique-os agora e receba cashback das filiações dos seus
            amigos.
          </Row>
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: 400,
            padding: 10,
          }}
        >
          <Row
            style={{
              fontSize: 38,
              fontFamily: "Inter",
              fontWeight: 800,
              color: "#012970",
              paddingTop: 20,
            }}
          >
            Cashback por indicação.
          </Row>
          <Row style={{ paddingTop: 20, fontSize: 22, fontWeight: 100 }}>
            Receba cashback por compartilhar nossos cursos de qualidade com seus
            amigos e familiares. Junte-se ao nosso clube de cashback por
            indicação e comece a receber ainda hoje!
          </Row>
          <Divider></Divider>
          <Row
            style={{
              paddingTop: 10,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              type="default"
              style={{
                width: 120,
                backgroundColor: "#30007e",
                color: "#fff",
              }}
              onClick={() => {
                navigate("/cadastroSite");
              }}
            >
              Cadastre-se
            </Button>
          </Row>
        </Col>
      </Row>
    </>
  );
};
