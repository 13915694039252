import { Button, Col, Collapse, Divider, Form, Input, message, Modal, Row, Select, Space, Table, Transfer } from "antd";
import { useEffect, useState } from "react";
import { CardFI } from "../../components/CardFI"
import { useApi } from "../../hooks/useApi";
import type { ColumnsType } from "antd/es/table";

import styles from "./styles.module.css";
import { TransferDirection } from "antd/lib/transfer";
import { DeleteFilled, EditFilled, SubnodeOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { render } from "@testing-library/react";

const { Option } = Select;

interface RecordType {
    key: string;
    title: string;
}

interface DataType {
    key: string;
    name: string;
    parent_desc: string;
    route: string;
}

export const MenuCad = () => {
    const api = useApi();

    const {idMenu} = useParams();
    const [form] = Form.useForm();
    const [parentCombo, setParentCombo] = useState([{key: "", value: ""}]);
    const [dataSource, setDataSource] = useState<DataType[]>();
    const [id, setId] = useState(null)
    const [name, setName] = useState('');
    const [parent, setParent] = useState('');
    const [route, setRoute] = useState('');
    const [initialTargetKeys, setInitialTargetKeys] = useState<string[]>([]);
    const [mockData, setMockData] = useState<RecordType[]>();
    const [targetKeys, setTargetKeys] = useState(initialTargetKeys);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const [displayCad, setDisplayCad] = useState('block');
    const [displayList, setDisplayList] = useState('none');

    const onChange = (nextTargetKeys: string[], direction: TransferDirection, moveKeys: string[]) => {
        setTargetKeys(nextTargetKeys);
    }

    const onSelectChange = (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    }

    const onReset = () => {
        form.resetFields();
        setTargetKeys([]);
    };

    const loadMenuParent = async () => {
        const comboParent = [{
            key: "Selecione um Módulo",
            value: "",
        }];

        const res = await api.getModuloCombo();
        res.forEach((value: {nome: any; id: any}) => {
            comboParent.push({
                key: value.nome,
                value: value.id
            })
        });
        setParentCombo(comboParent);                
    }

    const loadOptionsProfileMenu =async () => {
        const options = await api.getOptionsSelectPerfilMenu();
        if(options.length > 0){
            const newOptions: any = [];
            options.forEach((option: any) => {
                console.log(option);
               const o: RecordType = {
                key: option.id.toString(),
                title: option.nome,
               }
               newOptions.push(o);
            });
            setMockData(newOptions);
        }
        const init: any = mockData?.map(item => item.key);
        setInitialTargetKeys(init);
        
    }
    
    useEffect(()=>{
        loadOptionsProfileMenu();
        loadMenuParent();
    },[]);

    const onFinish = async (values: any) => {
        values.selectedKeys = targetKeys;
        const res = await api.saveMenu(values);
        message.info({
            content: res.message,
            className: 'custom-class',
            style: {
                marginTop: '20vh'
            }
        });
        if(res.status){
            onReset();
            loadOptionsProfileMenu();
        }else{            
            //openNotification('top', 'As senhas não são iguais!', 'A confirmação da senha não é igual a senha digitada.');
            message.error({
                content: 'Algo deu errado ao tentar salvar este menu.',
                className: 'custom-class',
                style: {
                    marginTop: '20vh'
                }
            });
        }
    };

    const onEdit = async (id: string) => {
        //buscar o registro
        const menu = await api.getMenu(id);
        form.setFieldValue('id', menu.id);
        form.setFieldValue('name', menu.nome);
        form.setFieldValue('modulo_id', menu.modulo_id);
        form.setFieldValue('route', menu.route);
        const init: any = [];        
        menu.perfis.map((item: any)=>{
            mockData?.map((mock, ind)=>{
                if(item.id == mock.key){
                    init.push(mock.key);
                }
            })
        });        
        setTargetKeys(init);
        setDisplayCad('block');
        setDisplayList('none');
    }

    //Lista
    const columns: ColumnsType<DataType> = [
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
            render: (_, value) => {
                if(value.parent_desc){
                    return <>&nbsp;&nbsp;&nbsp;&nbsp;<SubnodeOutlined />&nbsp;&nbsp;{value.name}</>
                }
                return value.name
            }
        },
        {
            title: 'Menu Base',
            dataIndex: 'parent_desc',
            key: 'parent_desc',
            render: (_, values) => {
                if(!values.parent_desc){
                    return <strong>{values.parent_desc}</strong>
                }
                return values.parent_desc;
            }
        },
        {
            title: 'Rota',
            dataIndex: 'route',
            key: 'route',
        },
        {
            title: 'Ações',
            key: 'action',
            render: (_, record) => {
                if(record.parent_desc){
                    return  (
                            <><Space size="middle">
                                <Button onClick={() => onEdit(record.key)}><EditFilled /></Button> 
                                <Button danger onClick={() => onDelete(record.key)}><DeleteFilled /></Button>
                            </Space></>
                    )
                }else{
                    return (<>Módulo</>)
                }
                
            }
        }
    ];

    const onDelete = (id: string) => {

    }

    const loadListMenus = async() => {        
        const menus = await api.listMenu(name, parent, route);
        if(menus.length > 0){
            const newData: any = [];
            menus.forEach((item: any)=>{
                const m: DataType = {
                    key: item.id,
                    name: item.nome,
                    parent_desc: item.menu_pai,
                    route: item.route,
                }
                newData.push(m);
                if(item.menus){
                    item.menus.forEach((line: any)=>{
                        const l: DataType = {
                            key: line.id,
                            name: line.nome,
                            parent_desc: item.nome,
                            route: line.route,
                        }
                        newData.push(l);
                    });
                }
            });
            setDataSource(newData);
        }
        setDisplayCad('none');
        setDisplayList('block');
    }

    const returnCad = () => {        
        setDisplayList('none'); 
        setDisplayCad('block');
    }

    return (
        <>
            {/* <CardFI id="cadMenu" display={displayCad} title="Cadastro de menus" size="90%"> */}
                <div style={{ backgroundColor: '#fff', padding: 5, borderRadius: '8px 8px 0 0' }}>
                    <Row style={{ padding: 5, fontWeight: 500 }}>Controle dos Menus</Row>
                    <Form
                        form={form}
                        name="register"
                        //labelCol={{span: 4}}
                        //wrapperCol={{span: 8}}
                        layout="vertical"
                        onFinish={onFinish}
                        style={{ "width": "100%", marginTop: 10}}
                        scrollToFirstError
                    >
                        <Form.Item initialValue={id} name="id" hidden={true}>
                            <Input type="hidden"></Input>
                        </Form.Item>
                        <Row style={{ marginTop: -10 }}>
                            <Col span={8} style={{ padding: '0 10px' }}>
                                <Form.Item label='Nome' name="name" rules={[{ required: true, message: 'O nome é obrigatório!' }]} >
                                    <Input type={'text'} 
                                        className={styles.borderRadius}
                                        placeholder="Nome do menu" 
                                        name='name' 
                                        id='name'
                                        onChange={e => setName(e.target.value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8} style={{ padding: '0 10px' }}>
                                <Form.Item label="Menu Base" name={'modulo_id'} initialValue={""} >
                                    <Select id='modulo_id' placeholder={'Selecione um Módulo'} className={styles.borderRadius}>
                                    {parentCombo.map((option, index) => {
                                        return (
                                            <Select.Option  className={styles.borderRadius} key={index} value={option.value}>{option.key}</Select.Option>
                                        )
                                    })}
                                    </Select>
                                </Form.Item>  
                            </Col>
                            <Col span={8} style={{ padding: '0 10px' }}>
                                <Form.Item label="Rota" name="route" initialValue={""}>
                                    <Input id='route' placeholder='Ex: menu' onChange={e => setRoute(e.target.value)} className={styles.borderRadius}/>
                                </Form.Item>  
                            </Col>
                        </Row>
                        <Divider style={{ marginTop: -10 }}>Permissões aos perfis para este menu</Divider>
                        <Row style={{ paddingInline: 10, display: 'flex', justifyContent: 'center' }}> 
                            <Form.Item>    
                                <Transfer
                                    dataSource={mockData}
                                    titles={['Perfis', 'Escolhido']}
                                    targetKeys={targetKeys}
                                    selectedKeys={selectedKeys}
                                    onChange={onChange}
                                    onSelectChange={onSelectChange}
                                    //onScroll={}
                                    render={item => item.title}
                                />
                            </Form.Item>                        
                        </Row>
                        <Divider style={{ marginTop: -10, marginBottom: 10 }}></Divider>
                        <Row justify="center">
                            <Form.Item style={{ marginBottom: 0 }}>
                                <Space>
                                    <Button type="primary" htmlType="submit" className={styles.borderRadius}>
                                        Salvar
                                    </Button>
                                    <Button htmlType="button" onClick={onReset} className={styles.borderRadius}>
                                        Limpar
                                    </Button> 
                                    <Button htmlType="button" onClick={loadListMenus} className={styles.borderRadius}>
                                        Pesquisar
                                    </Button> 
                                </Space>                   
                            </Form.Item>   
                        </Row>
                    </Form>
                </div>
            {/* </CardFI> */}
            {/* <CardFI id="listMenu" display={displayList} title="Lista de Menus cadastrados" size="95%"> */}
                <div style={{ height: '30vh' }} >    
                    <Row style={{ width: '100%', marginTop: 10 }}>
                        <Table columns={columns} dataSource={dataSource} style={{ width: '100%' }} pagination={{ pageSize: 50 }} scroll={{ y: 200 }}/>
                    </Row>
                    {/* <Row style={{ paddingTop: 10, display: 'flex', justifyContent: 'right', width: '100%' }}>
                        <Button style={{ backgroundColor: '#ff0000', color: '#fff', fontWeight: 500 }} onClick={returnCad}>Fechar</Button>
                    </Row> */}
                </div>
            {/* </CardFI> */}
        </>
    )
}