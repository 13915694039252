import { RocketOutlined } from "@ant-design/icons";
import { Button, Result } from "antd"
import { useNavigate } from "react-router-dom";
import { NavBar } from "../../components/NavBar"

import styles from "./styles.module.css";

export const PedidoPendente = () => {

    const navigate = useNavigate();

    const info = (
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', color: '#fff', textShadow: '2px 2px 8px #000'}}>
            <p>Seu cadastro foi efetivado, agora você pode acessar a plataforma, <strong>mas ainda não identificamos o pagamento.</strong></p>
            <p>Se ainda não o fez por favor faça o quanto antes para poder aproveitar os cursos da UnLocke.</p>
            <p>Se já fez o pagamento, aguarde uns instantes que vamos atualizar seu status.</p>
        </div>
    );

    return (
        <div className={styles.container}>
            <NavBar />
            <Result
                title={info}
                icon={<RocketOutlined style={{ color: '#FFF', }} />}
                extra={
                <Button onClick={() => {navigate('../login')}} type="primary" key="console">
                    Iniciar uma nova experiência
                </Button>
                }
            />
        </div>
    )
}