import {
  ApartmentOutlined,
  CopyOutlined,
  EditOutlined,
  EllipsisOutlined,
  EyeFilled,
  FolderOpenFilled,
  FolderOpenOutlined,
  FolderOpenTwoTone,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  notification,
  Rate,
  Row,
  Space,
  Tooltip,
} from "antd";
import { useContext, useState } from "react";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import { Cota } from "../../types/Cota";
import styles from "./styles.module.css";

type Props = {
  cota: Cota;
  onChange: (value: Cota) => void;
  onLoadEstrutura: (value: Cota) => void;
};

export const CotaCard = ({ cota, onChange, onLoadEstrutura }: Props) => {
  const auth = useContext(AuthContext);

  const handleCota = async (cota_target: Cota) => {
    onChange(cota_target);
  };

  return (
    <>
      <div className={styles.container}>
        <Card
          title={`Convite: ${cota.codigo_unico}`}
          type={"inner"}
          style={{ width: 250, boxShadow: "2px 2px 4px #333333" }}
        >
          <Row style={{ display: "flex", alignItems: "center" }}>
            <Space>
              Código:&nbsp;<strong>{cota.codigo_unico}</strong>
              <Button
                onClick={() => {
                  navigator.clipboard
                    .writeText(cota.codigo_unico)
                    .then(() => {
                      notification.open({
                        message: "Código copiado",
                        description:
                          "Código convite " +
                          cota.codigo_unico +
                          " copiado para área de transferência.",
                      });
                    })
                    .catch(() => {
                      notification.error({ message: "Erro ao copiar código!" });
                    });
                }}
              >
                <CopyOutlined />
              </Button>
            </Space>
          </Row>
          <Row>
            Saldo:&nbsp;
            <strong>
              R${parseFloat(cota.saldo).toFixed(2).replace(".", ",")}
            </strong>
          </Row>
          <Row>
            Nível:&nbsp;
            <strong>
              {parseInt(cota.nivel) > 0
                ? auth.nivel![parseInt(cota.nivel)]
                : "Sem nível definido"}
            </strong>
          </Row>
          <Divider style={{ marginTop: 5 }} />
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: -10,
            }}
          >
            <Space>
              {/* <Tooltip title={'Ver estrutura abaixo da cota.'}><Button onClick={() => onLoadEstrutura(cota)}><ApartmentOutlined style={{ color: '#0b0b3b', fontSize: 18 }} /></Button></Tooltip> */}
              <Tooltip title={"Detalhamento deste convite."}>
                <Button
                  onClick={() => handleCota(cota)}
                  style={{ border: "1px dashed #444444" }}
                >
                  <FolderOpenFilled
                    style={{ color: "#166d00", fontSize: 18 }}
                  />
                  Acessar o convite
                </Button>
              </Tooltip>
            </Space>
          </Row>
        </Card>
      </div>
    </>
  );
};
