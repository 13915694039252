import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation, EffectFade } from "swiper";

import styles from "./styles.module.css";
import { useEffect, useState } from "react";
import { CursoCard } from "../CursoCard";
import { useApi } from "../../hooks/useApi";
import { Divider, Empty, Input, Row } from "antd";

const { Search } = Input;

export const Banner = () => {
  const apiBanner = useApi();
  const [screenW, setScreenW] = useState(0);
  const [mini, setMin] = useState(400);
  const [cards, setCards] = useState([
    {
      id: "",
      nome: "",
      descricao: "",
      capa: "",
      rate: "",
      categorias_nome: "",
    },
  ]);

  const getCursos = async () => {
    await apiBanner.getCursos("", "").then((res) => {
      const list: any = [];
      if (res) {
        res.forEach(
          (value: {
            id: string;
            nome: string;
            descricao: string;
            rate: number;
            capa: string;
            categorias_nome: string;
          }) => {
            list.push({
              id: value.id,
              nome: value.nome,
              descricao: value.descricao,
              rate: Number(value.rate),
              capa: value.capa,
              categorias_nome: value.categorias_nome,
            });
          }
        );
        setCards(list);
      } else {
        setCards([]);
      }
    });
  };

  const onSearch = async (value: string) => {};

  useEffect(() => {
    setScreenW(window.innerWidth);
    getCursos();
  }, []);

  return (
    <div className={styles.container}>
      <Row>
        <Divider>Cursos da Plataforma</Divider>
      </Row>
      {/* <Row>
                <Search placeholder="Pesquise um curso" onSearch={onSearch} style={{ width: 300 }} />
            </Row> */}
      <Swiper
        slidesPerView={screenW < mini ? 1 : 5}
        className={styles.swiper}
        spaceBetween={10}
        centeredSlides={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: true,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        style={{ backgroundColor: "transparent" }}
      >
        <>
          {cards.length > 0 ? (
            cards.map((item, index) => {
              return (
                <SwiperSlide className={styles.slide} key={index}>
                  <CursoCard
                    id={item.id}
                    nome={item.nome}
                    descricao={item.descricao}
                    capa={item.capa}
                    rate={Number(item.rate)}
                    categorias_nome={item.categorias_nome}
                  />
                </SwiperSlide>
              );
            })
          ) : (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{
                height: 60,
              }}
              description={
                <span>Desculpe, mas ainda não temos cursos cadastrados!</span>
              }
            ></Empty>
          )}
        </>
      </Swiper>
    </div>
  );
};
