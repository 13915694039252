import {
  CloseCircleFilled,
  DollarOutlined,
  DotChartOutlined,
  HomeOutlined,
  MailOutlined,
  ReadOutlined,
  RocketOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Col,
  Collapse,
  Divider,
  Drawer,
  DrawerProps,
  Empty,
  List,
  Modal,
  notification,
  RadioChangeEvent,
  Row,
  Space,
} from "antd";
import { Layout } from "antd";
import { useContext, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import { RequireAuth } from "../../contexts/Auth/RequireAuth";
import styles from "./styles.module.css";
import { LogoTopoApp } from "../../components/LogoTopoApp";
import { MenuCustom } from "../../components/MenuCustom";
import { AcessoIndevido } from "../AcessoIndevido";
import { MenuCad } from "../MenuCad";
import { useApi } from "../../hooks/useApi";
import { BannerCota } from "../../components/BannerCota";
import { Cotas } from "../Cotas";
import { Cota } from "../../types/Cota";
import { Filiacao } from "../Filiacao";
import { Pedido } from "../Pedido";
import { OrdemPagamento } from "../OrdemPagamento";
import { VideoAula } from "../VideoAula";
import { CadCliente } from "../CadCliente";
import { ControlePagamento } from "../ControlePagamento";
import { AuthProvider } from "../../contexts/Auth/AuthProvider";
import { parseJsonText } from "typescript";
import { CursoAdm } from "../CursoAdm";
import { CategoriaCurso } from "../CategoriaCurso";
import Parametros from "../Parametros";

const { Panel } = Collapse;

const { Header, Content, Footer, Sider } = Layout;

export const Sistema = () => {
  const auth = useContext(AuthContext);
  const apiSistema = useApi();
  const navigate = useNavigate();
  const [mini, setMin] = useState(480);
  const { innerWidth: width, innerHeight: height } = window;
  const [collapsed, setCollapsed] = useState(false);

  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState<DrawerProps["placement"]>("right");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onLoadCota = async () => {
    await apiSistema.getCotas(auth.filiacao?.id!).then((res) => {
      console.log(res);
    });
  };

  useEffect(() => {
    //buscar o menu deste usuário
    if (width < mini) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
    //onLoadCota();
  }, []);

  const handleMenu = async (pag: string) => {
    try {
      onClose();
      navigate(pag);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCota = async (cota: Cota | null) => {
    auth.cota = cota;
    navigate("./cotas");
  };

  const onChange = (key: string | string[]) => {
    console.log(key);
  };

  return (
    <>
      <div className={styles.container}>
        <Layout style={{ minHeight: "100vh", backgroundColor: "transparent" }}>
          {/*auth.user?.isadm ? (
                        <Sider collapsed={collapsed} onCollapse={value => setCollapsed(value)} style={{ padding: '5px 10px 10px 10px', backgroundColor: 'transparent', minWidth: 250, width: 250 }}>
                            <Row style={{ height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#ffffff8b', borderRadius: 10 }}>
                                Administrador
                            </Row>
                            <Row>
                                <MenuCustom collapsed={true} font={"system/menu"} onChange={handleMenu} theme="light"/>
                            </Row>
                        </Sider>
                    ) : (
                        <></>
                    )*/}
          <Layout style={{ background: "transparent" }}>
            <Header style={{ padding: 0, backgroundColor: "transparent" }}>
              <Row style={{ display: "flex", justifyContent: "space-around" }}>
                {/*<Col>
                                     <a href='../sistema'><LogoTopoApp /></a> 
                                </Col>*/}
                <Col span={20}>
                  <Row style={{ color: "#ffffff", paddingTop: 5, width: 350 }}>
                    {/* <span style={{ fontSize: 18, fontWeight: 500, color: '#000' }}>{auth.user?.name}</span> */}
                    {/* <MenuCustom collapsed={collapsed} font={"system/menu"} onChange={handleMenu} theme="light"/> */}
                    <Col>
                      <Row>
                        <div style={{ marginTop: -12 }}>
                          <LogoTopoApp />
                        </div>

                        <span
                          style={{
                            fontWeight: 600,
                            fontFamily: "Roboto",
                            fontSize: 32,
                            textShadow: "2px 2px 4px #313131",
                          }}
                        >
                          UnLocke
                        </span>
                        <span
                          style={{
                            fontFamily: "Roboto",
                            fontSize: 38,
                            fontWeight: 50,
                            marginTop: -5,
                            paddingInline: 5,
                            textShadow: "2px 2px 4px #313131",
                          }}
                        >
                          |
                        </span>
                      </Row>
                    </Col>
                    <Col
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        fontWeight: 100,
                        fontFamily: "Roboto",
                        fontSize: 11,
                        height: 40,
                        marginTop: -12,
                      }}
                    >
                      <Row
                        style={{
                          width: "100%",
                          paddingInline: 5,
                          height: 22,
                          textShadow: "2px 2px 4px #313131",
                        }}
                      >
                        Plataforma de
                      </Row>
                      <Row
                        style={{
                          width: "100%",
                          paddingInline: 5,
                          height: 22,
                          textShadow: "2px 2px 4px #313131",
                        }}
                      >
                        transformação de vidas
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col
                  span={4}
                  style={{
                    paddingRight: 2.5,
                    paddingTop: -5,
                    display: "flex",
                    justifyContent: "right",
                  }}
                >
                  <Row>
                    <Space>
                      {/* <Badge count={3}> */}
                      <Avatar
                        onClick={showDrawer}
                        style={{
                          cursor: "pointer",
                          fontWeight: 500,
                          color: "#050505",
                          backgroundColor: "#d4a300",
                        }}
                      >
                        {auth.user?.name.charAt(0)}
                      </Avatar>
                      {/* </Badge>     */}
                    </Space>
                  </Row>
                </Col>
              </Row>
            </Header>
            <Content
              style={{
                margin: "0px 5px 25px 5px",
                backgroundColor: "transparent",
                display: "flex",
                flexDirection: "column",
                width: "99%",
                height: "70vh",
              }}
            >
              <Col
                style={{
                  width: "100%",
                  height: "75vh",
                  //backgroundColor: "#ffffff28",
                  borderRadius: 8,
                  overflow: "auto",
                }}
              >
                <Routes>
                  {/* <Route path='cotas' element={<RequireAuth levelReq={0}><Cotas /></RequireAuth> } />  */}
                  <Route
                    path="parametros"
                    element={
                      <RequireAuth levelReq={0}>
                        <Parametros />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="settings"
                    element={
                      <RequireAuth levelReq={0}>
                        <CadCliente />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="acessoindevido"
                    element={
                      <RequireAuth levelReq={0}>
                        <AcessoIndevido />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="menu"
                    element={
                      <RequireAuth levelReq={6}>
                        <MenuCad />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="controle_pagamento"
                    element={
                      <RequireAuth levelReq={6}>
                        <ControlePagamento />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="filiacao"
                    element={
                      <RequireAuth levelReq={0}>
                        <Filiacao />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="videoAulas"
                    element={
                      <RequireAuth levelReq={0}>
                        <VideoAula />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="lista_convites"
                    element={
                      <RequireAuth levelReq={0}>
                        <BannerCota />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="ordemPagamento"
                    element={
                      <RequireAuth levelReq={0}>
                        <OrdemPagamento />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="meusPedidos"
                    element={
                      <RequireAuth levelReq={0}>
                        <Pedido />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="admCursos"
                    element={
                      <RequireAuth levelReq={6}>
                        <CursoAdm />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="categoriaCurso"
                    element={
                      <RequireAuth levelReq={6}>
                        <CategoriaCurso />
                      </RequireAuth>
                    }
                  />
                </Routes>
              </Col>
              <Row
                style={{
                  position: "fixed",
                  bottom: 30,
                  width: "99%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#ffffff10",
                    width: "90%",
                    borderRadius: 5,
                    zIndex: 9999,
                    border: "1px solid #575757",
                  }}
                >
                  <Col
                    onClick={() => navigate("../sistema")}
                    className={styles.col_item_menu_start}
                  >
                    <HomeOutlined className={styles.icon_item_menu} />
                    <span className={styles.item_menu}>Início</span>
                  </Col>
                  <Col
                    onClick={() => {
                      auth.cotas != null
                        ? navigate("videoAulas")
                        : notification.warning({
                            message: "Desculpe mas falta algo!",
                            description:
                              "Percebemos que você não adquiriu nenhuma cota e por isso não tem uma filiação ativa. Veja bem você está cadastrado na plataforma mas não tem filiação ativa. Por favor vá em pedidos e finalize o pedido feito ou vá em cotas e adquira pelo menos uma cota. Qualquer dúvida entrar em contato com suporte.",
                          });
                    }}
                    className={styles.col_item_menu}
                  >
                    <RocketOutlined className={styles.icon_item_menu} />
                    <span className={styles.item_menu}>Cursos</span>
                  </Col>
                  {/* <Col className={styles.col_item_menu}>
                                            <ReadOutlined className={styles.icon_item_menu}  />
                                            <span className={styles.item_menu}>E-Books</span>                                            
                                        </Col> */}
                  <Col
                    onClick={() => navigate("lista_convites")}
                    className={styles.col_item_menu}
                  >
                    <MailOutlined className={styles.icon_item_menu} />
                    <span className={styles.item_menu}>Convites</span>
                  </Col>
                  <Col
                    onClick={() => navigate("ordemPagamento")}
                    className={styles.col_item_menu}
                  >
                    <DollarOutlined className={styles.icon_item_menu} />
                    <span className={styles.item_menu}>Receber</span>
                  </Col>
                  {/*<Col
                    onClick={() => navigate("meusPedidos")}
                    className={styles.col_item_menu}
                                      >
                    <ShoppingCartOutlined className={styles.icon_item_menu} />
                    <span className={styles.item_menu}>Pedidos</span>
                                      </Col>*/}
                </Row>
              </Row>
            </Content>
          </Layout>
        </Layout>
        <Footer
          style={{
            position: "fixed",
            bottom: 2.5,
            right: 5,
            textAlign: "right",
            backgroundColor: "transparent",
            color: "#ffffffc7",
            fontSize: 10,
            width: "98%",
            height: 10,
          }}
        >
          Unlocke ©2021 by RDCA-TI - Version: {process.env.REACT_APP_VERSION}
        </Footer>
        <Drawer
          title={"Plataforma UnLocke"}
          style={{
            borderRadius: "10px 0px 0px 10px",
            backgroundColor: "#ffffff",
          }}
          placement={placement}
          width={350}
          onClose={onClose}
          open={open}
          footer={
            <a
              style={{ width: "100%", color: "#f80000", fontWeight: 600 }}
              onClick={auth.signout}
            >
              <CloseCircleFilled />
              &nbsp;&nbsp;Sair
            </a>
          }
          // extra={
          //     <Space>
          //         <Switch
          //             checkedChildren={"Dark Theme"}
          //             unCheckedChildren={"Light Theme"}
          //             checked={isDarkMode}
          //             onChange={switchTheme}
          //         />
          //     </Space>
          // }
        >
          <p>
            <a
              href="#"
              onClick={() => {
                showModal();
                onClose();
              }}
            >
              <SettingOutlined />
              &nbsp;&nbsp;&nbsp;Configurar Perfil
            </a>
          </p>
          <Divider></Divider>
          <Row>
            <span style={{ fontSize: 12 }}>Nome</span>
          </Row>
          <Row>
            <span style={{ fontSize: 18 }}>{auth.user?.name}</span>
          </Row>
          {auth.user?.isadm ? (
            <Row style={{ display: "flex", justifyContent: "center" }}>
              <Divider>Menu Administrador</Divider>
              <MenuCustom
                collapsed={true}
                font={"system/menu"}
                onChange={handleMenu}
                theme="light"
              />
            </Row>
          ) : (
            <></>
          )}
          <Divider orientation="left">Filiação Ativa</Divider>
          <Row>
            <Col span={24}>
              <Row>
                <span style={{ fontSize: 16 }}>
                  <strong>{auth.filiacao?.plano_filiacao}</strong>
                </span>
              </Row>
              <Divider />
              <Row>
                <span style={{ fontSize: 12 }}>Saldo</span>
              </Row>
              <Row>
                <span style={{ fontSize: 16 }}>
                  <strong>R${auth.filiacao?.saldo}</strong>
                </span>
              </Row>
            </Col>
            {auth.cotas != null ? (
              <List
                size="small"
                header={<div>Convites</div>}
                //bordered
                style={{ width: "100%" }}
                dataSource={auth.cotas!}
                renderItem={(item) => (
                  <List.Item>
                    <Row style={{ width: "100%" }}>
                      <Col
                        span={24}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <a
                          href="#"
                          style={{
                            display: "flex",
                            verticalAlign: "middle",
                            fontSize: 16,
                            color: "#444444",
                          }}
                          onClick={() => {
                            handleCota(item);
                          }}
                        >
                          <DotChartOutlined />
                          &nbsp;&nbsp;&nbsp;Cota: {item.id}
                        </a>
                        <span>
                          Nível: <strong>{item.nivel}</strong>
                        </span>
                      </Col>
                      <Col
                        span={24}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        Saldo: <strong>R${item.saldo}</strong>
                        Convite acima: {item.cota_acima}
                      </Col>
                      <Col span={24}>
                        Código Convite: <strong>{item.codigo_unico}</strong>
                      </Col>
                    </Row>
                  </List.Item>
                )}
              />
            ) : (
              <></>
            )}
          </Row>
          <Divider />
        </Drawer>
        <Modal
          title={`Cadastro de ${auth.user?.name}`}
          open={isModalOpen}
          footer
          onCancel={handleCancel}
          onOk={handleOk}
        >
          <CadCliente />
        </Modal>
      </div>
    </>
  );
};
