import { DollarOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  List,
  notification,
  Row,
  Select,
  Spin,
  Tooltip,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { CardFI } from "../../components/CardFI";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import { useApi } from "../../hooks/useApi";

export const OrdemPagamento = () => {
  const api = useApi();
  const auth = useContext(AuthContext);
  const [load, setLoad] = useState(false);
  const [messageLoad, setMessageLoad] = useState("");
  const [ordens, setOrdens] = useState([]);
  const [data, setData] = useState([]);

  const getOrdens = async () => {
    setMessageLoad(
      "Buscando ordens, essa ação pode demorar um pouco. Aguarde..."
    );
    setLoad(true);
    await api
      .getOrders()
      .then((res) => {
        res.forEach((item: any) => {
          const [data, time] = item["created_at"].split("T");
          const [ano, mes, dia] = data.split("-");
          item["created_at"] = `${dia}/${mes}/${ano}`;
          item["valor"] = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(item["valor"]);
        });
        setOrdens(res);
      })
      .catch((err) => {
        notification.error({
          message: "Tivemos problemas para solicitar suas ordens.",
          description:
            "Algo inesperado ocorreu com esta ação. Por favor refaça a ação.",
        });
      });
    setMessageLoad("");
    setLoad(false);
  };

  const cancelarPagamento = async (id: string) => {
    setMessageLoad("Processando cancelamento da solicitação do pagamento.");
    setLoad(true);
    await api.cancelOrdens(id).then((res) => {
      notification.success({ message: res.message });
      getOrdens();
    });
    setMessageLoad("");
    setLoad(false);
  };

  useEffect(() => {
    getOrdens();
    console.log("Tela: ", window.screen.width);
  }, []);

  return (
    <>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          padding: 10,
          color: "#fff",
          fontSize: 14,
          fontFamily: "Roboto",
          fontWeight: 400,
        }}
      >
        MINHAS SOLICITAÇÕES DE RECEBIMENTO
      </Row>
      <Divider style={{ margin: 5 }} />
      <div
        style={{
          height: "85%",
          overflow: "auto",
          paddingTop: 10,
          paddingInline: 2,
          //width: "99%",
        }}
      >
        <Spin spinning={load} tip={messageLoad}>
          <List
            size="small"
            // header={<div style={{ display: 'flex', justifyContent: 'center', padding: 5, fontSize: 18, color: '#fcfcfc', backgroundColor: '#0000009e', borderRadius: 10 }}>Ordens de recebimento</div>}
            //bordered
            style={{ width: "100%" }}
            dataSource={ordens!}
            renderItem={(item) => (
              <List.Item>
                <Card style={{ width: "100%", padding: 5 }}>
                  <Row style={{ width: "100%" }}>
                    <Col style={{ padding: 5 }}>
                      <Row>Id</Row>
                      <Row>{item["id"]}</Row>
                    </Col>
                    <Col style={{ padding: 5 }}>
                      <Row>Solicitado</Row>
                      <Row>
                        <strong>{item["created_at"]}</strong>
                      </Row>
                    </Col>
                    <Col style={{ padding: 5 }}>
                      <Row>Valor</Row>
                      <Row>
                        <strong>{item["valor"]}</strong>
                      </Row>
                    </Col>
                    <Col style={{ padding: 5 }}>
                      <Row>Pix para</Row>
                      <Row>
                        <strong>
                          {item["tipo_chave_pix"]} - {item["chave_pix"]}
                        </strong>
                      </Row>
                    </Col>
                    <Col style={{ padding: 5 }}>
                      <Row>Pago em</Row>
                      <Row>
                        {item["pagamento"] != null
                          ? item["pagamento"]
                          : "Aguardando pagamento"}
                      </Row>
                    </Col>
                    <Col style={{ padding: 5 }}>
                      <Row>Código</Row>
                      <Row>
                        {item["codigo_transacao"] != null
                          ? item["codigo_transacao"]
                          : "Aguardando pagamento"}
                      </Row>
                    </Col>
                    <Col style={{ padding: 5, right: 0 }}>
                      <Row>Ação</Row>
                      <Row>
                        {item["codigo_transacao"] != null ? (
                          ""
                        ) : (
                          <Button
                            danger
                            onClick={() => cancelarPagamento(item["id"])}
                          >
                            Cancelar Pagamento
                          </Button>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </List.Item>
            )}
          />
        </Spin>
      </div>
    </>
  );
};

function setOrdens(res: any) {
  throw new Error("Function not implemented.");
}
