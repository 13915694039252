import { Button, Col, Row, Tabs } from "antd";
import { Footer } from "antd/lib/layout/layout";
import { useNavigate } from "react-router-dom";
import { NavBar } from "../../components/NavBar";
import { TabCompartilhamento } from "../../components/TabCompartilhamento";
import { TabMaterialExclusivo } from "../../components/TabMaterialExclusivo";
import { TabPlanoNegocio } from "../../components/TabPlanoNegocio";
import styles from "./styles.module.css";
import { useEffect, useRef } from "react";
export const FiliacaoPlano = () => {
  const navigate = useNavigate();
  const videoRef = useRef(document.createElement("video"));

  useEffect(() => {
    videoRef.current.play();
  }, []);

  return (
    <>
      <NavBar />
      <div className={styles.container}>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 50,
          }}
        >
          <div>
            {/* <img
              style={{ width: 400 }}
              src="./imgs/team-work-business-solve-puzzles-find-ideas_4968-469.jpg"
            /> */}
          </div>
          <div
            style={{
              padding: 10,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontFamily: "Segoe UI",
                fontSize: 28,
                color: "#313131",
                lineHeight: 1.2,
              }}
            >
              <strong>
                Torne-se um membro exclusivo e tenha vantagens para o seu
                conhecimento, além de fazer parte do Plano de Bonificações.
              </strong>
            </p>
            <Row
              style={{
                width: "100%",
                paddingTop: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <video
                ref={videoRef}
                src={
                  "https://unlockeapi.rdca.com.br/public/storage/videos_site/Apresentação%20-%20Unlocke%20‐%20Feito%20com%20o%20Clipchamp.mp4"
                }
                controls
                controlsList="nodownload"
                style={{
                  height: "60vh",
                  borderRadius: 5,
                  backgroundColor: "transparent",
                }}
              />
            </Row>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Arial",
                fontSize: 22,
                color: "#4e4e4e",
                lineHeight: 1.2,
                paddingTop: 10,
              }}
            >
              Os membros do club UnLocke tem acesso a diversos cursos
              esclusivos, e-books e várias funcionalidades e bonificações
              diferenciadas da plataforma, além de acesso a toda a biblioteca.
            </p>
            <Row
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                style={{ width: 200 }}
                type="primary"
                onClick={() => {
                  navigate("../cadastroSite");
                }}
              >
                Quero ser UnLocke
              </Button>
            </Row>
          </div>
        </Row>
      </div>
      <Footer
        style={{
          position: "fixed",
          bottom: 0,
          textAlign: "right",
          backgroundColor: "transparent",
          color: "#252525",
          fontSize: 12,
          width: "100vw",
        }}
      >
        Unlocke ©2021 Created by RDCA Tecnologia - Version:{" "}
        {process.env.REACT_APP_VERSION}
      </Footer>
    </>
  );
};
