import { Row } from "antd";
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import { useApi } from "../../hooks/useApi";
import { Cota } from "../../types/Cota";

export const EstruturaLista = () => {

    const auth = useContext(AuthContext);
    const api = useApi();
    const [estrutura, setEstrutura]= useState([]);

    const [linha1, setLinha1] = useState([]);
    const [linha2, setLinha2] = useState([]);
    const [linha3, setLinha3] = useState([]);
    const [linha4, setLinha4] = useState([]);
    const [linha5, setLinha5] = useState([]);
    const [linha6, setLinha6] = useState([]);
    const [linha7, setLinha7] = useState([]);
    const [linha8, setLinha8] = useState([]);
    const [linha9, setLinha9] = useState([]);
    const [linha10, setLinha10] = useState([]);

    const getEstrutura = async() => {
        await api.getEstrutura(auth.cota!.id).then((res) => {
            setEstrutura(res);
            setLinha1(res[1]);
            setLinha2(res[2]);
            setLinha3(res[3]);
            setLinha4(res[4]);
            setLinha5(res[5]);
            setLinha6(res[6]);
            setLinha7(res[7]);
            setLinha8(res[8]);
            setLinha9(res[9]);
            setLinha10(res[10]);
            console.log(linha1)
        });
    }
    
    useEffect(() => {
        if(auth.cota != null){
            getEstrutura();
        }
    },[auth.cota]);

    return (
        <>
            <Row>Estrutura da Cota {auth.cota?.id}</Row>
            <Row>
                {linha1}
            </Row>
        </>
    );
}