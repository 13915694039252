import { Col, Row } from "antd"

export const TabPlanoNegocio = () => {
    return (
        <div style={{ padding: '10px 20px', display: 'flex', justifyContent: 'center' }}>
            <Row style={{ display:'flex', alignItems: 'center', width: 400 }}>
                <Col span={24} style={{ padding: 10, display: 'flex', justifyContent: 'center' }}>
                    <img src="./imgs/planejar_office.PNG" height="150" /> 
                </Col>
                <Col style={{ textAlign: 'justify' }}>                      
                    {/* <a href="{{ route('club.planoNegocio') }}">Conheça o Plano de Negócio</a> */}
                    &nbsp;&nbsp;&nbsp;&nbsp;Junte-se a nós no Unlocke e desbloqueie o acesso à nossa incrível biblioteca de conhecimento. Expandir seus horizontes nunca foi tão fácil - com nossa assinatura, você terá acesso a todo o nosso conteúdo exclusivo, incluindo webinars, e-books, podcasts e muito mais. E o melhor: ao indicar seus amigos e familiares, eles também terão acesso a esses benefícios incríveis. Juntos, poderemos alcançar novos patamares de sucesso e crescimento profissional. Não perca mais tempo e assine agora!<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;Como recompensa por indicar seus amigos, a Unlocke transferirá para você uma porcentagem do valor pago por eles. Você pode acumular esses valores e sacá-los assim que estiverem disponíveis no sistema.<br/>
                </Col>
                
            </Row>
        </div>
    )
}