import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";

import styles from "./styles.module.css";
import { useContext, useEffect, useState } from "react";
import { CursoCard } from "../CursoCard";
import { useApi } from "../../hooks/useApi";
import { stringify } from "querystring";
import localeValues from "antd/lib/locale/pt_BR";
import { Curso } from "../../types/Curso";
import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  FloatButton,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
  Spin,
} from "antd";
import { useAsyncError, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import { CotaCard } from "../CotaCard";
import { Cota } from "../../types/Cota";
import { PagamentoMP } from "../PagamentoMP";
import { EstruturaCota } from "../EstruturaCota";
import { EstruturaLista } from "../EstruturaLista";
import { PlusOutlined } from "@ant-design/icons";

const { Search } = Input;

export const BannerCota = () => {
  const auth = useContext(AuthContext);
  const apiBanner = useApi();
  const navigate = useNavigate();
  const [screenW, setScreenW] = useState(0);
  const [mini, setMin] = useState(480);
  const [cards, setCards] = useState<Cota[]>([]);
  const [itemsQtd, setItemsQtd] = useState(1);
  const [messageLoad, setMessageLoad] = useState("");
  const [load, setLoad] = useState(false);
  const [valorPedido, setValorPedido] = useState<string | null>("19,90");
  const [qtdCota, setQtdCota] = useState<number | null>(1);
  const [codepreference, setCodepreference] = useState("");
  const [openPagamento, setOpenPagamento] = useState(false);
  const [nome, setNome] = useState("");
  const [cpf, setCpf] = useState("");
  const [email, setEmail] = useState("");
  const [celular, setCelular] = useState("");
  const [produtonome, setProdutonome] = useState("");
  const [produtoquantidade, setProdutoquantidade] = useState("");
  const [produtovalor, setProdutovalor] = useState("");
  const [isModalEstrutura, setIsModalEstrutura] = useState(false);
  const [openModalCompra, setOpenModalCompra] = useState(false);
  const [change, setChange] = useState([]);
  const [formCompra] = Form.useForm();

  //Modal Estrutura
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    auth.cota = null;
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    auth.cota = null;
    setIsModalOpen(false);
  };

  const estruturaCancel = () => {
    auth.cota = null;
    setIsModalEstrutura(false);
    navigate("../lista_convites");
  };

  const estruturaOk = () => {
    auth.cota = null;
    setIsModalEstrutura(false);
    navigate("../lista_convites");
  };

  const getCotas = async () => {
    if (auth.filiacao!.id) {
      setMessageLoad(
        "Buscando seus convites ativas. Essa ação pode demorar um pouco. Aguarde..."
      );
      setLoad(true);
      await apiBanner.getCotas(auth.filiacao!.id).then((res) => {
        //console.log(res);
        const list: Cota[] = [];
        if (res) {
          res.forEach((value: Cota) => {
            list.push(value);
          });
          setCards(list);
        } else {
          setCards([]);
        }
      });
      setMessageLoad("");
      setLoad(false);
    }
  };

  const construcCotaBase = (cota: any) => {};

  const onSearch = async (value: string) => {};

  const onPayment = () => {
    setOpenPagamento(true);
  };

  const onFinish = async (values: any) => {
    values.quantidade_cota = qtdCota;
    formCompra.resetFields();
    closeCompra();
    setMessageLoad("Gerando seu pedido, aguarde...");
    setLoad(true);
    await apiBanner
      .cashCote(
        values.id_filiacao,
        values.codigo_anfitriao,
        values.quantidade_cota
      )
      .then((res) => {
        notification.info({
          message: res.message,
          //btn: (<Button type="primary" size="small" onClick={() => {openCompra(res.pessoa.id);}}>Filiar-se</Button>)
        });
        //console.log(res.data);
        if (res.data) {
          const data = res.data;
          //Data Mercado Pago
          setCodepreference(data.pedido.code_preference);
          setChange(data.preference);
          setNome(data.pessoa.nome);
          setCpf(data.pessoa.cpf);
          setEmail(data.pessoa.email);
          setCelular(data.pessoa.celular);
          setProdutonome("Convite da Plataforma Clube UnLocke");
          setProdutoquantidade(data.pedido.quantidade_cota);
          setProdutovalor(data.pedido.total_pedido);
          setOpenPagamento(true);
          //Data Efi
          /*setCodepreference(data.txid);
          setChange(data.txid);
          setNome(data.devedor.nome);
          setCpf(data.devedor.cpf);
          setEmail(data.pessoa.email);
          setCelular(data.pessoa.celular);
          setProdutonome(`${data.item.nome} - ${data.item.descricao}`);
          setProdutoquantidade(data.item.quantidade);
          setProdutovalor(data.total);
          setOpenPagamento(true);*/
        }
      })
      .catch((erro) => {
        notification.error({
          message: erro.response.data.message,
          //btn: (<Button type="primary" size="small" onClick={() => {openCompra(res.pessoa.id);}}>Filiar-se</Button>)
        });
      });
    setLoad(false);
    setMessageLoad("");
  };

  const closePagamento = (open: boolean) => {
    setCodepreference("");
    setOpenPagamento(false);
    navigate("../lista_convites");
  };

  const returnPagamento = async (retorno: any) => {
    setOpenPagamento(false);
    navigate("../lista_convites");
  };

  useEffect(() => {
    const valor: number = 19.9;
    if (qtdCota) {
      const total: number = qtdCota * valor;
      setValorPedido(total.toString());
    } else {
      notification.error({
        message: "A quantidade de convites não pode ser nulo.",
      });
    }
  }, [qtdCota]);

  const loadCota = (cota: Cota) => {
    auth.cota = cota;
    setIsModalOpen(true);
  };

  const loadEstruturaCota = (cota: Cota) => {
    auth.cota = cota;
    setIsModalEstrutura(true);
  };

  const closeCota = () => {
    auth.cota = null;
    setIsModalOpen(false);
    getCotas();
  };

  const openCompra = () => {
    setOpenModalCompra(true);
  };

  const closeCompra = () => {
    formCompra.resetFields();
    setOpenModalCompra(false);
  };

  // useEffect(() => {
  //     if(auth.cota != null){
  //         setIsModalOpen(true);
  //     }else{
  //         setIsModalOpen(false);
  //     }
  // }, [auth.cota])

  useEffect(() => {
    setScreenW(window.innerWidth);
    const qtdCard = Math.floor(window.innerWidth / 300);
    setItemsQtd(qtdCard);
    getCotas();
  }, []);

  useEffect(() => {
    !auth.cota ?? setIsModalOpen(false);
    !auth.cota ?? estruturaOk();
    console.log("Checando: ", auth.cota);
    !auth.cota ?? getCotas();
  }, [auth.cota]);

  return (
    <>
      <Spin tip={messageLoad} spinning={load}>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            width: "95%",
            padding: 10,
            color: "#fff",
            fontSize: 18,
            fontFamily: "Roboto",
            fontWeight: 400,
          }}
        >
          MEUS CONVITES
        </Row>
        {/* <Divider style={{ margin: -10 }} /> */}
        <div className={styles.container}>
          {/* <Row>
                    <Search placeholder="Pesquise um curso" onSearch={onSearch} style={{ width: 380 }} />
                </Row> */}
          {/* <div style={{ display: 'flex', justifyContent: 'center', padding: 5, marginBottom: 10, fontSize: 18, color: '#fcfcfc', backgroundColor: '#0000009e', borderRadius: 10 }}>Minha{cards.length > 1 ? 's' : ''} Cota{cards.length > 1 ? 's' : ''}</div> */}
          {}

          <Row
            style={{ display: "flex", justifyContent: "center", width: "99%" }}
          >
            {cards.length > 0 ? (
              cards.map((item, index) => {
                return (
                  <CotaCard
                    cota={item}
                    onLoadEstrutura={loadEstruturaCota}
                    onChange={loadCota}
                  />
                );
              })
            ) : (
              <Empty
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{
                  height: 60,
                }}
                description={<span>Sem cotas.</span>}
              ></Empty>
            )}
          </Row>
          {/*<Swiper
                        slidesPerView={itemsQtd}
                        className={styles.swiper}
                        spaceBetween={5} 
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[Autoplay, Pagination, Navigation]}                       
                    >
                        <>
                            {
                                cards.length > 0 ?
                                    cards.map((item, index)=>{
                                            return (<SwiperSlide key={index} className={styles.slide}><CotaCard cota={item} onLoadEstrutura={loadEstruturaCota} onChange={loadCota} /></SwiperSlide>)
                                    })
                                :
                                <Empty
                                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                    imageStyle={{
                                    height: 60,
                                    }}
                                    description={
                                        <span>
                                            Sem cotas.
                                        </span>
                                    }
                                >
                                </Empty>
                            }
                        </>
                        </Swiper>*/}
          <FloatButton
            type="primary"
            style={{ bottom: 90 }}
            icon={<PlusOutlined />}
            onClick={() => openCompra()}
          ></FloatButton>

          <PagamentoMP
            title="Pagamento do Pedido"
            open={openPagamento}
            code_preference={codepreference}
            nome={nome}
            cpf={cpf}
            celular={celular}
            email={email}
            nome_produto={produtonome}
            valor_produto={produtovalor}
            quantidade_produto={produtoquantidade}
            onChange={returnPagamento}
            onClose={closePagamento}
          />
          <Modal
            centered
            open={openModalCompra}
            title={"Aquisição de Novo Convite"}
            footer
            onCancel={closeCompra}
            onOk={closeCompra}
          >
            <Row style={{ display: "flex", justifyContent: "left" }}>
              {/* <Card style={{ padding: 10, boxShadow: '2px 2px 4px #333333' }} title={<div style={{ color: '#000000', }}>Adquirir Cotas</div>}>                         */}
              <div
                style={{
                  backgroundColor: "#fff",
                  padding: 10,
                  borderRadius: 10,
                }}
              >
                <Form
                  name="formCompra"
                  form={formCompra}
                  onFinish={onFinish}
                  style={{ marginBottom: -10 }}
                >
                  <Form.Item hidden name={"id_filiacao"} initialValue={1}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={"Convite"}
                    name={"codigo_anfitriao"}
                    rules={[
                      {
                        required: true,
                        message:
                          "É obrigatório um convite para adquirir novos convites. Você pode usar um convite próprio. Caso não tenha, pode solicitar!",
                      },
                    ]}
                  >
                    <Input
                      style={{ width: 200 }}
                      onInput={(e) => {
                        e.currentTarget.value =
                          e.currentTarget.value.toUpperCase();
                      }}
                    />
                  </Form.Item>

                  {/* <Form.Item label={'Seu Código'} name={'codigo_anfitriao'} tooltip={'Para você ter um código mais pessoal, vamos deixar que você defina qual o código. Só lembre-se que esse código deve ser de 8 caracteres e deve ter letras e números'} rules={[{ required: true, message: 'É obrigatório um convite para se filiar. Caso não tenha, pode solicitar!' }]}>
                                        <Input style={{ width: 200 }} onInput={(e) => {e.currentTarget.value = e.currentTarget.value.toUpperCase()}} />
                                    </Form.Item> */}

                  <Form.Item
                    label={"Convite(s)"}
                    name={"quantidade_cota"}
                    tooltip={
                      "O convite é uma unidade para receber seus cashbacks. Você pode ter vários convites e distribuir seus amigos, montando assim grupos. A quantidade máxima de convites para compra é 100 unidades."
                    }
                  >
                    <Input.Group compact>
                      <InputNumber
                        name="quantidade_cota"
                        style={{ width: 80 }}
                        min={1}
                        max={100}
                        defaultValue={1}
                        onChange={setQtdCota}
                        value={qtdCota}
                      />
                      <Button>{`R\$ ${parseFloat(valorPedido!)
                        .toFixed(2)
                        .replace(".", ",")}`}</Button>
                    </Input.Group>
                  </Form.Item>
                  <Form.Item
                    wrapperCol={{ offset: 8 }}
                    style={{ marginTop: -10 }}
                  >
                    {codepreference == "" ? (
                      <Button
                        className={styles.styleInput}
                        type="primary"
                        htmlType="submit"
                      >
                        Adquirir
                      </Button>
                    ) : (
                      <Button
                        className={styles.styleInput}
                        type="primary"
                        onClick={onPayment}
                      >
                        Pagar
                      </Button>
                    )}
                  </Form.Item>
                </Form>
              </div>
              {/* </Card> */}
            </Row>
          </Modal>
          <Modal
            centered
            width={1000}
            title={`Convite ${auth.cota?.codigo_unico}`}
            open={isModalOpen}
            footer
            onCancel={handleCancel}
            onOk={handleOk}
          >
            <EstruturaCota closeCota={closeCota} />
          </Modal>
          <Modal
            centered
            width={1000}
            footer
            title={`Estrutura do convite ${auth.cota?.id}`}
            open={isModalEstrutura}
            onCancel={estruturaCancel}
            onOk={estruturaOk}
          >
            <EstruturaLista />
          </Modal>
        </div>
      </Spin>
    </>
  );
};
