import { PesquisaCurso } from "../../components/PesquisaCurso"
import { CadastroCurso } from "../CadastroCurso"

export const CursoAdm = () => {
    return (
        <>
            <CadastroCurso />
            <PesquisaCurso />
        </>
    )
}