import { Col, Row } from "antd"

export const TabMaterialExclusivo = () => {
    return (
        <div style={{ padding: '10px 20px', display: 'flex', justifyContent: 'center' }}>
            <Row style={{ display:'flex', alignItems: 'center', width: 400 }}>
                <Col span={24} style={{ padding: 10, display: 'flex', justifyContent: 'center' }}>
                    <img src="./imgs/planejar.jpg" height="200" />
                </Col>
                <Col style={{ textAlign: 'justify' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;Os membros do nosso clube de afiliados têm acesso exclusivo a uma variedade de materiais exclusivos, que não estão disponíveis para outros usuários.<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;As atualizações são realizadas regularmente e todo o conteúdo está disponível sem restrições para auxiliar na produção de seus conteúdos.<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;Com este conteúdo exclusivo, você se destacará em relação a muitas outras pessoas que buscam alcançar sucesso e independência financeira.<br/>
                </Col>
            </Row>
        </div>
    )
}