import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  List,
  Modal,
  Row,
  Skeleton,
  Spin,
  Watermark,
} from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import { useApi } from "../../hooks/useApi";
import Background from "../../assets/imgs/back.jpg";

type Props = {
  title: string;
  curso: string | null;
  onChange: (retorno: any) => void;
  onClose: (open: boolean) => void;
  open: boolean;
};

type Aula = {
  id: string | null;
  titulo: string | null;
  fonte: string | null;
  duracao: string | null;
};

export const Aula = ({ title, curso, onChange, onClose, open }: Props) => {
  const auth = useContext(AuthContext);
  const api = useApi();
  const videoRef = useRef(document.createElement("video"));

  const [loading, setLoading] = useState(false);
  const [messageLoad, setMessageLoad] = useState("Carregando...");
  const [aulas, setAulas] = useState<Aula[]>([]);
  const [aula, setAula] = useState<Aula | null>(null);
  const [stop, setStop] = useState(false);

  const getAulas = async () => {
    setMessageLoad("Buscando os vídeos do curso, aguarde...");
    setLoading(true);
    await api.getAulas(curso!).then((res) => {
      if (res) {
        const list: any = [];
        res.forEach(
          (value: {
            id: string;
            titulo: string;
            fonte: string;
            duracao: string;
          }) => {
            list.push({
              id: value.id,
              titulo: value.titulo,
              fonte: value.fonte,
              duracao: value.duracao,
            });
          }
        );
        setAulas(list);
      } else {
        setAulas([]);
      }
      setAula(null);
    });
    setMessageLoad("");
    setLoading(false);
  };

  const closeVideo = () => {
    setAula(null);
    videoRef?.current?.pause();
    onClose(false);
    console.log("fechou");
  };

  useEffect(() => {
    getAulas();
  }, [curso]);

  return (
    <>
      <Modal
        title={title}
        centered
        open={open}
        onOk={() => closeVideo()}
        onCancel={() => closeVideo()}
        width={"100%"}
        footer
        bodyStyle={{ backgroundColor: "#000000" }}
        style={{
          backgroundColor: "#000",
          backgroundImage: "url(" + { Background } + ")",
        }}
      >
        {/* <Divider>Aulas</Divider> */}
        <Spin tip={messageLoad} spinning={loading}>
          <Row>
            <Col
              span={6}
              style={{
                height: "90vh",
                overflowY: "auto",
                padding: 5,
                backgroundColor: "#000",
                backgroundImage: "url(" + { Background } + ")",
                borderRadius: 5,
              }}
            >
              {aulas.map((item: any, index) => {
                return (
                  <Row style={{ paddingBottom: 5 }}>
                    <Card
                      onClick={() => setAula(item)}
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#ffcc00",
                        border: "1px solid #00000030",
                        width: "100%",
                        padding: 5,
                      }}
                    >
                      <Row>
                        <Col
                          span={4}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: 24,
                            paddingRight: 10,
                          }}
                        >
                          {index + 1}
                        </Col>
                        <Col span={20}>
                          <Row style={{ fontWeight: 700, width: "100%" }}>
                            {item.titulo?.split(" - ")[0]}
                          </Row>
                          <Row style={{ fontSize: 12, width: "100%" }}>
                            {item.titulo?.split(" - ")[1]}
                          </Row>
                          <Row style={{ fontSize: 10, width: "100%" }}>
                            Duração: {item.duracao}
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                  </Row>
                );
              })}
            </Col>
            <Col span={18}>
              {auth!.user ? (
                aula != null ? (
                  <>
                    <Row style={{ paddingInline: 10, paddingBottom: 20 }}>
                      Assistindo:&nbsp;<strong>{aula.titulo}</strong>
                    </Row>
                    <Watermark
                      content={auth?.user?.cpf}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <video
                        ref={videoRef}
                        src={aula!.fonte!}
                        controls
                        controlsList="nodownload"
                        style={{ width: "90%", borderRadius: 8 }}
                      />
                    </Watermark>
                    {/* <div style={{ backgroundColor: 'transparent', width: '100%', height: '80%', zIndex: 9999, position: 'absolute', top: 0 }}></div> */}
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: 24,
                      height: "100%",
                    }}
                  >
                    <Empty
                      description={<span>Nenhum vídeo selecionado</span>}
                    />
                  </div>
                )
              ) : (
                <div style={{ paddingInline: 10, paddingBottom: 20 }}>
                  Nenhum Curso
                </div>
              )}
            </Col>
          </Row>
        </Spin>
      </Modal>
    </>
  );
};
