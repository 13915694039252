import { Col, Row } from "antd"

export const TabCompartilhamento = () => {
    return (
        <div style={{ padding: '10px 20px', display: 'flex', justifyContent: 'center' }}>
            <Row style={{ display:'flex', alignItems: 'center', width: 400 }}>
                <Col span={24} style={{ padding: 10, display: 'flex', justifyContent: 'center' }}>
                    <img src="./imgs/produzir_office.PNG" height="150" />
                </Col>
                <Col style={{ textAlign: 'justify', fontSize: 16 }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;Agora que você adquiriu e aplicou todo o conhecimento adquirido, é hora de compartilhar suas descobertas com os demais.<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;Chegamos ao ponto de compartilhar nosso conhecimento e ajudar outras pessoas. Este momento é crucial, pois não é hora de guardar informações.<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;Com o conhecimento adquirido, produza conteúdo valioso e convide outras pessoas a se juntarem ao Clube UnLocke e aproveitar também das vantagens e oportunidades oferecidas.<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;Divulgue, incentive e amplie sua comunidade e seu círculo de contatos compartilhando sua experiência e convidando outras pessoas para participar.<br/>
                </Col>
            </Row>
        </div>
    )
}