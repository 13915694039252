import { Col, Row } from "antd"
import { NavBar } from "../../components/NavBar"

export const Privacidade = () => {
    return (
        <>
            {/* <NavBar /> */}

            <Row style={{ backgroundColor: '#fff', display: 'flex', justifyContent: 'center', padding: 10, height: '75vh', overflow: 'auto' }}>
                {/* <Col style={{ display: 'flex', justifyContent: 'start', flexDirection: 'column', width: 400, padding: 10  }}>
                    <Row style={{ display: 'flex', justifyContent: 'center' }}><img style={{ width: 400 }} src={'/imgs/cafe.png'} /></Row>
                </Col> */}
                <Col style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', width: 400, padding: 10  }}>
                    <Row style={{ fontSize: 28, fontFamily: 'Inter', fontWeight: 800, color: '#012970', paddingTop: 20, borderBottom: '1px solid silver' }}>
                        Política de Privacidade
                    </Row>
                    <Row style={{ paddingTop: 20, fontSize: 18, fontWeight: 100, color: '#525252' }}>
                        A nossa política de privacidade é criada para ajudar você a entender como coletamos, usamos e protegemos as informações pessoais que você fornece ao usar nosso site de cursos online. Ao usar nosso site, você concorda com a coleta e uso de informações de acordo com essa política.
                    </Row>
                    <Row style={{ paddingTop: 20, fontSize: 18, fontWeight: 100, color: '#525252' }}>
                        <strong>Coleta de Informações</strong><br/> Coletamos informações quando você se cadastra em nosso site, faz uma compra, preenche um formulário ou entra em contato conosco. As informações coletadas podem incluir, mas não se limitam a, seu nome, endereço de e-mail, endereço postal e número de telefone. Além disso, coletamos informações sobre seu uso do nosso site e sobre suas compras e transações.
                    </Row>
                    <Row style={{ paddingTop: 20, fontSize: 18, fontWeight: 100, color: '#525252' }}>
                        <strong>Uso de Informações</strong><br/> As informações coletadas são usadas para fornecer e melhorar os serviços oferecidos em nosso site. Elas também podem ser usadas para personalizar sua experiência no site, responder suas perguntas e comunicações, enviar informações relevantes sobre o site e atualizações de cursos, e processar transações.
                    </Row>
                    <Row style={{ paddingTop: 20, fontSize: 18, fontWeight: 100, color: '#525252' }}>
                        <strong>Segurança de Informações</strong><br/> Adotamos medidas de segurança para proteger as informações pessoais contra acesso não autorizado, alteração, divulgação ou destruição. No entanto, nenhuma transmissão de dados pela Internet ou armazenamento de dados é completamente seguro. Portanto, embora façamos todos os esforços razoáveis para proteger suas informações, não podemos garantir a segurança absoluta das informações transmitidas ou armazenadas em nosso site.
                    </Row>
                    <Row style={{ paddingTop: 20, fontSize: 18, fontWeight: 100, color: '#525252' }}>
                        <strong>Divulgação de Informações</strong><br/> Nós não vendemos, trocamos ou divulgamos suas informações pessoais para terceiros sem sua permissão, exceto quando necessário para fornecer os serviços solicitados, cumprir com as leis aplicáveis ou proteger nossos direitos e propriedades.
                    </Row>
                    <Row style={{ paddingTop: 20, fontSize: 18, fontWeight: 100, color: '#525252' }}>
                        <strong>Links para outros sites</strong><br/> Nosso site pode conter links para outros sites. No entanto, uma vez que você clicar nesses links e deixar nosso site, não temos mais controle sobre as informações coletadas por esses sites e não somos responsáveis pela política
                    </Row>
                </Col>
            </Row>
        </>
    )
}