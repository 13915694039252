import React, { useContext, useEffect, useState } from "react";
import { ConfigProvider } from "antd";
import ptBR from "antd/lib/locale/pt_BR";
import "./App.css";
import { Home } from "./pages/Home";
import { AuthProvider } from "./contexts/Auth/AuthProvider";
import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "./contexts/Auth/RequireAuth";
import { Sobre } from "./pages/Sobre";
import { Contato } from "./pages/Contato";
import { CadastroSite } from "./pages/CadastroSite";
import { Compra } from "./pages/Compra";
import { FiliacaoPlano } from "./pages/FiliacaoPlano";
import { ThemeData } from "./types/ThemeData";
import { Login } from "./pages/Login";
import { Sistema } from "./pages/Sistema";
import { AuthContext } from "./contexts/Auth/AuthContext";
import { PedidoPendente } from "./pages/PedidoPendente";
import { TermoUso } from "./pages/TermoUso";
import { Privacidade } from "./pages/Privacidade";
import axios from "axios";
import { MessagePayload, onMessage } from "firebase/messaging";
import { getFirebaseToken, messaging } from "./FirebaseConfig";

const defaultData: ThemeData = {
  borderRadius: 6,
  colorPrimary: "#012970",
};

const configPub = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Authorization",
    "Access-Control-Allow-Methods": "*",
    "Content-Type": "application/json;charset=UTF-8",
  },
};

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
  withCredentials: true,
  xsrfCookieName: "csrf-token",
  xsrfHeaderName: "X-CSRF-TOKEN",
});
/*
if (
  navigator.userAgent.match(/webOS/i) ||
  navigator.userAgent.match(/iPhone/i) ||
  navigator.userAgent.match(/iPad/i) ||
  navigator.userAgent.match(/iPod/i)
) {
  // está utilizando apple
} else {
  window.Notification.requestPermission((permission) => {
    if (permission === "granted") {
      navigator.serviceWorker.register("service-worker.js");
    }
  });
  window.Notification.requestPermission((permission) => {
    if (permission === "granted") {
      navigator.serviceWorker
        .register("service-worker.js")
        .then(async (serviceWorker) => {
          let subscription = await serviceWorker.pushManager.getSubscription();
          if (!subscription) {
            const publicKeyResponse = await api.post(
              "/push/getpublickey",
              {},
              configPub
            );
            subscription = await serviceWorker.pushManager.subscribe({
              applicationServerKey: publicKeyResponse.data.publickey,
            });
          }
          console.log(subscription);
        });
    }
  }); // não é apple
}
*/
//Firebase
interface NotificationPayloadProps {
  data?: MessagePayload | undefined;
  open: boolean;
}

function App() {
  const [data, setData] = useState<ThemeData>(defaultData);
  const logo = require("./assets/imgs/unlocke_icon_2.png");
  const [open, setOpen] = useState(false);

  //To store notification data from firebase
  const [notificationPayload, setNotificationPayload] = useState<
    (NotificationPayloadProps | undefined)[]
  >([]);

  //This is self invoking function that listen of the notification
  const onMessageListener = async () => {
    const messagingResolve = await messaging;
    if (messagingResolve) {
      onMessage(messagingResolve, (payload: MessagePayload) => {
        setNotificationPayload([{ data: payload, open: true }]);
        setTimeout(() => setNotificationPayload([{ open: false }]), 6000);
      });
    }
  };

  const handleGetFirebaseToken = () => {
    getFirebaseToken().then((firebaseToken: string | undefined) => {
      if (firebaseToken) {
        localStorage.setItem("fcm_token", firebaseToken);
      } else {
        localStorage.setItem("fcm_token", "");
      }
    });
  };

  //Need this handle FCM token generation when a user manually blocke or allows
  useEffect(() => {
    if (
      //"Notifications" in window &&
      window.Notification?.permission === "granted"
    ) {
      handleGetFirebaseToken();
    } else {
      console.log("Not authorized!");
    }
  }, []);

  return (
    <ConfigProvider
      locale={ptBR}
      theme={{
        token: {
          colorPrimary: data.colorPrimary,
          borderRadius: data.borderRadius,
          linkHoverDecoration: "none",
        },
      }}
    >
      <>
        {/* <div>
          <div>
            {"Notification" in window &&
              Notification.permission !== "granted" && (
                <div className="notification-banner">
                  <span>The app needs permission to</span>
                  <a
                    href="#"
                    className="notification-banner-link"
                    onClick={handleGetFirebaseToken}
                  >
                    enable push notifications.
                  </a>
                </div>
              )}

            {notificationPayload.map((notification) => {
              return (
                <>
                  {notification?.open && (
                    <>
                      <div className="notification">
                        <div className="push-notification-title">
                          <h1>{notification?.data?.notification?.title}</h1>
                          <button
                            className="close-button"
                            onClick={() =>
                              setNotificationPayload([{ open: false }])
                            }
                          >
                            X
                          </button>
                        </div>
                        <div>
                          <h1 className="push-notification-text">
                            {notification?.data?.notification?.body}
                          </h1>
                        </div>
                      </div>
                    </>
                  )}
                </>
              );
            })}

            {open && (
              <div
                className="notification"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <div className="push-notification-title">
                  <h1>New Message</h1>
                  <button
                    className="close-button"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    X
                  </button>
                </div>
                <div>
                  <h1 className="push-notification-text">
                    Hello Welcome, Today you will learn how to use
                    firebase-notifications
                  </h1>
                </div>
              </div>
            )}
          </div>
        </div> */}

        <AuthProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<Sobre />} />
            <Route path="/contact" element={<Contato />} />
            <Route path="/cadastroSite/:envite?" element={<CadastroSite />} />
            <Route path="/oportunidade/:envite?" element={<CadastroSite />} />
            <Route path="/compra/:id" element={<Compra />} />
            <Route path="/filiacaoplano" element={<FiliacaoPlano />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/sistema/*"
              element={
                <RequireAuth levelReq={0}>
                  <Sistema />
                </RequireAuth>
              }
            />
            <Route path="/pedidoPendente" element={<PedidoPendente />} />
            <Route path="/termouso" element={<TermoUso />} />
            <Route path="/politicaprivacidade" element={<Privacidade />} />
          </Routes>
        </AuthProvider>
      </>
    </ConfigProvider>
  );
}

export default App;
