import {
  Avatar,
  Button,
  Carousel,
  Col,
  Descriptions,
  Divider,
  Image,
  Input,
  Row,
  Tabs,
} from "antd";
import { Footer } from "antd/lib/layout/layout";
import { useNavigate } from "react-router-dom";
import { Banner } from "../../components/Banner";
import { NavBar } from "../../components/NavBar";
import styles from "./styles.module.css";
import { useRef, useState } from "react";
import {
  motion,
  useScroll,
  useSpring,
  useTransform,
  MotionValue,
} from "framer-motion";
import Title from "antd/es/typography/Title";
import image1 from "../../assets/imgs/photo-1516321497487-e288fb19713f.jpg";
import { TabMaterialExclusivo } from "../../components/TabMaterialExclusivo";
import { TabPlanoNegocio } from "../../components/TabPlanoNegocio";
import { TabCompartilhamento } from "../../components/TabCompartilhamento";

export const Home = () => {
  const navigate = useNavigate();
  const [mini, setMin] = useState(480);
  const [atualWidth, setAtualWidth] = useState(window.innerWidth);
  const { innerWidth: width, innerHeight: height } = window;

  const contentStyle: React.CSSProperties = {
    height: "160px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };

  const itemTab = [
    {
      label: "Exclusividade",
      key: "tab-1",
      children: <TabMaterialExclusivo />,
    },
    { label: "Conhecimento", key: "tab-2", children: <TabPlanoNegocio /> },
    {
      label: "Compartilhamento",
      key: "tab-3",
      children: <TabCompartilhamento />,
    },
  ];

  function useParallax(value: MotionValue<number>, distance: number) {
    return useTransform(value, [0, 1], [-distance, distance]);
  }

  return (
    <>
      <NavBar />
      <Row
        style={{
          backgroundImage: "url('./imgs/hero-bg.png')",
          width: "99vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 10,
          paddingTop: 40,
        }}
      >
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            width: 400,
            padding: 10,
          }}
        >
          <Row
            style={{
              fontSize: 42,
              fontFamily: "Inter",
              fontWeight: 800,
              color: "#012970",
              paddingTop: 20,
            }}
          >
            Esteja pronto para embarcar em uma nova jornada emocionante.
          </Row>
          <Row style={{ paddingTop: 20, fontSize: 22, fontWeight: 100 }}>
            Alcance seu máximo potencial com nossos cursos de alta qualidade.
            Adquira novos conhecimentos e habilidades valiosas e compartilhe sua
            experiência com amigos e colegas.
          </Row>
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            width: 400,
            padding: 20,
          }}
        >
          <img className="floating" src="./imgs/xedu_ilastration.webp" />
        </Col>
      </Row>
      <Row
        style={{
          backgroundColor: "#f5f5f5",
          width: "99vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 10,
        }}
      >
        {/* <Col>
                    <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: 400, padding: 10  }}>
                        <Row style={{ fontSize: 42, fontFamily: 'Inter', fontWeight: 800, color: '#012970', paddingTop: 20 }}>
                            <img style={{ height: 400 }} src="./imgs/women_cel_unlocke.png"  />
                        </Row>
                        <Row style={{ paddingTop: 20, fontSize: 18, fontWeight: 100, color: '#525252' }}>
                            A plataforma UnLocke oferece uma ampla variedade de cursos práticos, com acesso ilimitado a todo o 
                            nosso acervo de conteúdo educacional.
                        </Row>
                        <Row style={{ paddingTop: 10, display: 'flex', justifyContent: 'center', width: 400 }}>
                            <Button type="default" style={{ width: 100, backgroundColor: '#30007e', color: '#fff' }} onClick={() => {navigate('/filiacaoplano')}}>Saber mais</Button>
                        </Row>
                    </Row>
                </Col> */}
        <Col style={{ /*width: 400,*/ padding: 10 }}>
          <Banner />
        </Col>
      </Row>
      <Row
        style={{
          backgroundColor: "#c8d2ee",
          width: "99vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: 40,
          paddingTop: 40,
          paddingInline: 10,
        }}
      >
        <Row>
          <Title style={{ fontWeight: 900, fontSize: 48, width: "80%" }}>
            Ganhe dinheiro indicando cursos on-line.
          </Title>
        </Row>

        <Row style={{ width: "100%", marginTop: 30 }}>
          <Row
            style={{
              fontSize: 24,
              display: "flex",
              justifyContent: "center",
              width: "100%",
              paddingLeft: 30,
            }}
          >
            Inscreva-se em nossos cursos on-line e ganhe dinheiro indicando
            amigos e familiares.
          </Row>
        </Row>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 30,
            width: "100%",
          }}
        >
          <Row
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Avatar
              size={42}
              src="https://storage.googleapis.com/mixo-files/public/img/avatars/male-3.png"
              alt="Alan Costa"
            />
            <Row style={{ paddingLeft: 10 }}>
              <Row style={{ width: "100%", fontSize: 18, fontStyle: "italic" }}>
                “Já indiquei vários amigos e recebi cashback em todas as
                inscrições. É uma ótima forma de ganhar dinheiro enquanto
                estudo!”
              </Row>
              <Row>Alan Costa</Row>
            </Row>
          </Row>
        </Row>
        <Row style={{ marginTop: 30 }}>
          <Button
            type="default"
            style={{ width: 120, backgroundColor: "#4a0078", color: "#ffffff" }}
            onClick={() => {
              navigate("/cadastroSite");
            }}
          >
            Cadastrar-se
          </Button>
        </Row>
      </Row>
      <Row
        style={{
          width: "99vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 10,
        }}
      >
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            width: 400,
            padding: 10,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Row style={{ paddingTop: 20 }}>
            <img style={{ width: 400, borderRadius: 8 }} src={image1} />
          </Row>
          <Row
            style={{
              paddingTop: 10,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <span
              style={{ fontSize: 14, textAlign: "center", color: "#313131" }}
            >
              Faça parte do{" "}
              <strong style={{ fontSize: 22, color: "green" }}>
                Clube Exclusivo UnLocke
              </strong>
              , e tenha acesso a cursos transformadores, além de participar do
              programa de{" "}
              <strong style={{ fontSize: 22, color: "green" }}>
                cashbacks
              </strong>{" "}
              UnLocke.
            </span>
            <Divider></Divider>
            <Button
              type="default"
              style={{ width: 120, backgroundColor: "#30007e", color: "#fff" }}
              onClick={() => {
                navigate("/cadastroSite");
              }}
            >
              Cadastrar-se
            </Button>
          </Row>
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: 400,
            padding: 10,
            paddingLeft: 20,
            alignItems: "center",
          }}
        >
          <Row
            style={{
              fontFamily: "Roboto",
              color: "#30007e",
              fontSize: 32,
              fontWeight: 800,
            }}
          >
            Torne-se um membro valioso do nosso clube.
          </Row>
          <Row style={{ paddingTop: 10, color: "#5c5c5c" }}>
            Como membro exclusivo do nosso clube, você terá acesso a uma
            variedade de cursos que podem transformar sua vida de diversas
            maneiras. Além disso, você terá a oportunidade de convidar seus
            amigos e compartilhar esses conhecimentos valiosos com eles.
          </Row>
        </Col>
      </Row>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          width: "100%",
          paddingBottom: 30,
        }}
      >
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col>
            <strong style={{ fontSize: 28 }}>
              O que oferecemos nesse club?
            </strong>
          </Col>
        </Row>
        <Tabs centered items={itemTab} />
      </Row>
      <Footer
        style={{
          position: "fixed",
          bottom: 0,
          textAlign: "right",
          backgroundColor: "transparent",
          color: "#252525",
          fontSize: 12,
          width: "100vw",
          paddingInline: 10,
          zIndex: 9999,
        }}
      >
        Unlocke ©2021 Created by RDCA Tecnologia - Version:{" "}
        {process.env.REACT_APP_VERSION}
      </Footer>
    </>
  );
};
