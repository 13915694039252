import { Col, Row } from "antd"
import { NavBar } from "../../components/NavBar"

export const TermoUso = () => {
    return(
        <>
            {/* <NavBar /> */}

            <Row style={{ backgroundColor: '#fff', display: 'flex', justifyContent: 'center', padding: 10, height: '70vh', overflow: 'auto' }}>
                {/* <Col style={{ display: 'flex', justifyContent: 'start', flexDirection: 'column', width: 400, padding: 10  }}>
                    <Row style={{ display: 'flex', justifyContent: 'center' }}><img style={{ width: 400 }} src={'/imgs/cafe.png'} /></Row>
                </Col> */}
                <Col style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', width: 400, padding: 10  }}>
                    <Row style={{ fontSize: 28, fontFamily: 'Inter', fontWeight: 800, color: '#012970', paddingTop: 20, borderBottom: '1px solid silver' }}>
                        Termos de Uso
                    </Row>
                    <Row style={{ paddingTop: 20, fontSize: 18, fontWeight: 100, color: '#525252' }}>
                        Bem-vindo ao nosso site de cursos online, onde oferecemos cursos de alta qualidade e programas de bonificação por indicação. Ao usar nosso site, você concorda em seguir e estar sujeito aos seguintes termos de uso:
                    </Row>
                    <Row style={{ paddingTop: 20, fontSize: 18, fontWeight: 100, color: '#525252' }}>
                        <strong>1.&nbsp;&nbsp;Conta de Usuário:</strong><br/> Para acessar alguns recursos do nosso site, é necessário criar uma conta de usuário. Ao criar uma conta, você concorda em fornecer informações precisas e atuais sobre você mesmo e manter essas informações atualizadas.
                    </Row>
                    <Row style={{ paddingTop: 20, fontSize: 18, fontWeight: 100, color: '#525252' }}>
                        <strong>2.&nbsp;&nbsp;Pagamentos:</strong><br/> Todos os pagamentos e compras de cursos em nosso site devem ser feitos através dos meios de pagamento aceitos. Ao fazer uma compra, você concorda em pagar todas as taxas aplicáveis, incluindo impostos, encargos e taxas de transação.
                    </Row>
                    <Row style={{ paddingTop: 20, fontSize: 18, fontWeight: 100, color: '#525252' }}>
                        <strong>3.&nbsp;&nbsp;Bonificações por Indicação:</strong><br/> Oferecemos programas de bonificação por indicação, onde os usuários podem ganhar créditos ou descontos ao indicar amigos e familiares para se inscreverem em nossos cursos. As bonificações por indicação estão sujeitas a termos e condições específicos e podem ser alteradas ou canceladas a qualquer momento.
                    </Row>
                    <Row style={{ paddingTop: 20, fontSize: 18, fontWeight: 100, color: '#525252' }}>
                        <strong>4.&nbsp;&nbsp;Conteúdo:</strong><br/> Todo o conteúdo disponibilizado em nosso site, incluindo textos, gráficos, imagens, áudios e vídeos, são de propriedade exclusiva de nós ou de nossos licenciadores e estão protegidos por direitos autorais e marcas registradas. Você concorda em não reproduzir, duplicar, copiar, vender ou explorar comercialmente qualquer parte do nosso site sem nossa permissão prévia por escrito.
                    </Row>
                    <Row style={{ paddingTop: 20, fontSize: 18, fontWeight: 100, color: '#525252' }}>
                        <strong>5.&nbsp;&nbsp;Isenção de Responsabilidade:</strong><br/> O conteúdo disponibilizado em nosso site é fornecido "tal como está" e "conforme disponível" sem garantias de qualquer tipo, expressas ou implícitas. Não garantimos que o uso do nosso site será ininterrupto ou livre de erros, e não nos responsabilizamos por quaisquer danos decorrentes do uso do nosso site.
                    </Row>
                    <Row style={{ paddingTop: 20, fontSize: 18, fontWeight: 100, color: '#525252' }}>
                        <strong>6.&nbsp;&nbsp;Modificações dos Termos de Uso:</strong><br/> Reservamo-nos o direito de modificar estes termos de uso a qualquer momento e sem aviso prévio. Sua continuidade no uso do nosso site após qualquer modificação constitui sua aceitação dos novos termos.
                    </Row>
                </Col>
            </Row>
        </>
    )
}