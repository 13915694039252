import axios from 'axios';

const getConfig = (type: string) => {

    const configPub = {
        headers: {                  
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Authorization", 
            "Access-Control-Allow-Methods": "*" ,
            "Content-Type": "application/json;charset=UTF-8" ,                 
        },
    };
    
    const configPriv = {    
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Authorization", 
            "Access-Control-Allow-Methods": "*" ,
            "Content-Type": "application/json;charset=UTF-8",
            "Authorization": `Bearer ${localStorage.getItem('authToken')}`,
        }
    };

    if(type === "priv"){
        return configPriv;
    } 
           
    return configPub;
}

let url: string = '';

/*if (window.location.hostname === 'unlocke.com.br') {
    url = 'https://unlockeapi.rdca.com.br/api'; 
} else {
    url = 'http://localhost/api';
}*/

const api = axios.create({
    baseURL: !window.location.hostname.indexOf('unlocke.com.br') ? process.env.REACT_APP_API_PROD : process.env.REACT_APP_API_DESV,
    withCredentials: true,
    xsrfCookieName: "csrf-token",
    xsrfHeaderName: 'X-CSRF-TOKEN',
});

export const useApi = () => ({

    contatoMessage: async(values: any) => {
        const response = await api.post('/send/message/faleconosco', {values}, getConfig('pub'));
        return response.data;
    },
    getPublicKeyNotification: async() => {
        const response = await api.post('/push/getpublickey', {}, getConfig('pub'));
        return response.data;
    },
    validateToken: async (token: string) => {
        console.log(token);
        const response = await api.post('/auth/validate', {token}, getConfig("priv"));
        return response.data;
    },
    signin: async (email: string, password: string) => {
        const response = await api.post('/auth/login', {email, password});
        return response.data;
    },
    logout: async (token: string) => {
        const response = await api.post('/auth/logout', {token}, getConfig("pub"));
        return response.data;
    },
    subscribe: async (id_filiacao: string, nome: string, cpf: string, sexo: string, email: string, celular: string, password: string, c_password: string, aceite_termos: boolean, codigo_anfitriao: string, quantidade_cota: string, fcm_token: string) => {
        const response = await api.post('/auth/register',  {id_filiacao, nome, cpf, sexo, email, celular, password, c_password, aceite_termos, codigo_anfitriao, quantidade_cota, fcm_token}, getConfig("pub"));
        return response.data;
    },
    cashCote: async (id_filiacao: string, codigo_anfitriao: string, quantidade_cota: string) => {
        const response = await api.post('/pedido/cashCota', {id_filiacao, codigo_anfitriao, quantidade_cota}, getConfig('priv'));
        return response.data;
    },
    getEnderecoCep: async (cep:string) => {
        const response = await api.get("https://viacep.com.br/ws/"+cep+"/json/", getConfig('pub'));
        return response;
    },
    newPassword: async (cpf: string) => {
        const response = await api.post('/resetpassword', {cpf}, getConfig('pub'));
        return response.data;
    },
    setWebHook: async (webhook: string) => {
        const response = await api.post('/setwebhook', { webhook }, getConfig('priv'));
        return response;
    },
    //Menu
    getMenuDashboard: async (font: string) => {
        const response = await api.post(font, {}, getConfig("priv"));
        return response.data;
    },
    getMenuParentCombo:async () => {
        const response = await api.post("/menu/parent/combo", {}, getConfig("priv"));
        return response.data;        
    },
    listMenu: async(name: string, parent: string, route: string) => {
        const response = await api.post("/menu/list", {'name': name, 'parent': parent, 'route': route}, getConfig("priv"));
        return response.data;
    },
    saveMenu:async (values:any) => {
        const response = await api.post('/menu/save', {values}, getConfig("priv"));
        return response.data;
    },
    getOptionsSelectPerfilMenu:async () => {
        const response = await api.post('/menu/profile', {}, getConfig('priv'));
        return response.data;
    },
    getMenu:async (id:string) => {
        const response = await api.post('/menu/find', {id}, getConfig('priv'));
        return response.data;
    },
    //Modulo
    getModuloCombo:async () => {
        const response = await api.post('/modulo/combo', {}, getConfig('priv'));
        return response.data;
    },
    insertModulo:async (cliente: String, modulo: String) => {
        const response = await api.post('/modulo/insert', {cliente, modulo}, getConfig('priv'));
        return response.data;
    },
    removeModulo:async (cliente: String, modulo: String) => {
        const response = await api.post('/modulo/remove', {cliente, modulo}, getConfig('priv'));
        return response.data;
    },
    //Pais
    getCountryCombo: async () => {
        const response = await api.post('/country/combo', {}, getConfig('pub'));
        return response.data;
    },
    //Estado
    getStateCombo: async () => {
        const response = await api.post('/state/combo', {}, getConfig('pub'));
        return response.data;
    },
    getEstadoNome: async (uf: string) => {
        const response = await api.post('/estado/get/nome', {uf}, getConfig('priv'));
        return response.data;
    },
    //Cidade
    getCityCombo:async (uf: string) => {
        const response = await api.post('/city/combo', { uf }, getConfig('pub'));
        return response.data;
    },
    getCidadeNome: async (nome: string) => {
        const response = await api.post('/cidade/get/nome', {nome}, getConfig('priv'));
        return response.data;
    },
    //Pessoa
    savePersona:async (values: any) => {
        const response = await api.post('/persona/save', {values}, getConfig('priv'));
        return response.data;
    },
    listPersona: async(search: string) => {
        const response = await api.post('/persona/list', {search}, getConfig('priv'));
        return response.data;
    },
    getPersona:async (id:string) => {
        const response = await api.post('/persona/get', {id}, getConfig('priv'));
        return response.data;
    },
    updatePersona: async(values: any) => {
        const response = await api.post('/persona/update', {values}, getConfig('priv'));
        return response;
    },
    //User
    listUser:async () => {
        const response = await api.post('/list/user', {}, getConfig('priv'));
        return response.data;
    },
    getUser:async (id: string) => {
        const response = await api.post('/get/user', {id}, getConfig('priv'));
        return response.data;
    },
    getUserPersona:async(id_persona: string) => {
        const response = await api.post('/find/user/persona', {id_persona}, getConfig('priv'));
        return response.data;
    },
    openPedido:async(id_pessoa:number, produtos: []) => {
        const response = await api.post('/open/pedido', {id_pessoa, produtos}, getConfig('pub'));
        return response.data;
    },
    requestPassword: async(email: string) => {
        const response = await api.post('/request/password', {email}, getConfig('pub'));
        return response.data;
    },
    //CategoriaCurso
    comboCategoriaCurso: async() => {
        const response = await api.post('/categoria/combo', {}, getConfig('pub'));
        return response.data;
    },
    saveCategoriaCurso: async(values: any) => {
        const response = await api.post('/categoria/save', {values}, getConfig('priv'));
        return response.data;
    },
    //Curso
    getCursos: async(nome: string, categoria: string) => {
        const response = await api.post('/curso/cards', {nome, categoria}, getConfig('pub'));
        return response.data;
    },
    insertCurso: async(value: any) => {
        const response = await api.post('/curso/insert', {value}, getConfig('priv'));
        return response.data;
    },
    pesquisaCurso: async(value: any) => {
        const response = await api.post('/curso/pesquisa', {value}, getConfig('priv'));
        return response.data;
    },
    //Aulas
    getAulas:async (curso_id:string) => {
        const response = await api.post('/aula/get', {curso_id}, getConfig('priv'));
        return response.data;
    },
    saveAula: async(values: any) => {
        const response = await api.post('/aula/post', {values}, getConfig('priv'));
        return response.data;
    },
    //Cotas
    getCotas: async(filiacao: string) => {
        const response = await api.post('/cota/getCotaFiliacao', {filiacao}, getConfig('priv'));
        return response.data;
    },
    getStruct: async(target: string) => {
        const response = await api.post('/cota/getStruct', {target}, getConfig('priv'));
        return response.data;
    },
    getEstrutura: async(target: string) => {
        const response = await api.post('/cota/getEstrutura', {target}, getConfig('priv'));
        return response.data;
    },
    //Ordem Pagamento
    registerPayment: async(values: any) => {
        const response = await api.post('/ordem/registrar', {values}, getConfig('priv'));
        return response.data;
    },
    getOrders: async () => {
        const response = await api.post('/ordem/list', {}, getConfig('priv'));
        return response.data;
    },
    getOrdersAll: async () => {
        const response = await api.post('/ordem/list/all', {}, getConfig('priv'));
        return response.data;
    },
    cancelOrdens: async (id: string) => {
        const response = await api.post('/ordem/cancel', {id}, getConfig('priv'));
        return response.data;
    },
    executarOrdem: async (values: any) => {
        const response = await api.post('/ordem/executarOrdem', {values}, getConfig('priv'));
        return response.data;
    },
    //Pedido
    loadPedidoFiliado: async () => {
        const response = await api.post('/pedido/loadPedidoFiliado', {}, getConfig('priv'));
        return response.data;
    },
    cancelPedido: async (id: string) => {
        const response = await api.post('/pedido/cancel', {id}, getConfig('priv'));
        return response.data;
    }
})