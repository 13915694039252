import { DotChartOutlined } from "@ant-design/icons";
import { Col, Divider, List, Row, Skeleton, Typography } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import Title from "antd/es/typography/Title";
import { useContext } from "react"
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import { CardFI } from "../../components/CardFI";
import { AuthContext } from "../../contexts/Auth/AuthContext"
import { useApi } from "../../hooks/useApi";
import { Cota } from "../../types/Cota";

export const Filiacao = () => {

    const auth = useContext(AuthContext);
    const api = useApi();
    const navigate = useNavigate();

    const handleCota = async (cota: Cota | null) => {
        auth.cota = cota;
        navigate('./cotas')
    }

    return (
        <>
            {
                auth.filiacao ? (
                    <CardFI title="Filiação" display="block" id="filiacao" size="90%">
                        <>
                            <Row>
                                <span style={{ fontSize: 16 }}><strong>{auth.filiacao?.plano_filiacao}</strong></span>
                            </Row>
                            <Divider />
                            <Row style={{ padding: 10 }}>                        
                                <Col span={10}>                            
                                    <Row>
                                        <span style={{ fontSize: 12 }}>Saldo</span>
                                    </Row>
                                    <Row>
                                        <span style={{ fontSize: 16 }}><strong>R${auth.filiacao?.saldo}</strong></span>
                                    </Row>
                                </Col>
                                
                                <Col style={{ maxHeight: '50vh', overflow: 'auto' }}>
                                    <List
                                        size='small'
                                        header={<div><strong>{auth.cotas!.length}&nbsp;Cota{auth.cotas!.length > 1 ? 's' : ''}</strong></div>}
                                        //bordered
                                        style={{ width: '100%' }}
                                        dataSource={auth.cotas!}
                                        renderItem={item => (
                                            <List.Item>
                                                <Row style={{ width: '100%' }}>
                                                    <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <a href="#" style={{ display: 'flex', verticalAlign: 'middle', fontSize: 16, color: '#444444', }} onClick={() => {handleCota(item)}}><DotChartOutlined />&nbsp;&nbsp;&nbsp;Cota: {item.id}</a> 
                                                        <span>Nível: <strong>{item.nivel}</strong></span>
                                                    </Col>
                                                    <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <span>Saldo: <strong>R${item.saldo}</strong></span>
                                                        <span>Cota acima: <strong>{item.cota_acima}</strong></span>
                                                    </Col>
                                                    <Col span={24}>
                                                        Código Convite: <strong>{item.codigo_unico}</strong>
                                                    </Col>
                                                </Row>
                                            </List.Item>
                                        )}
                                    />
                                </Col>
                                
                            </Row>
                        </>
                    </CardFI>
                ) : (
                    <Typography>
                        <Title>Não há filiacão ativa</Title>
                        <Paragraph>
                            Não identificamos uma filiação ativa, essa situação acontece porque você ainda não adquiriu uma cota, ou ainda não efetuou o pagamento de um pedido em aberto. Resolva as pendências para que possa ter acesso a todo o acervo da nossa plataforma, e receber suas bonificações.
                        </Paragraph>
                    </Typography>
                )
            }
        </>
    )
}