import {
  Badge,
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Input,
  Modal,
  notification,
  Rate,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
  Watermark,
} from "antd";
import Meta from "antd/es/card/Meta";
import { useContext, useEffect, useState } from "react";
import { Aula } from "../../components/Aula";
import { Banner } from "../../components/Banner";
import { CardFI } from "../../components/CardFI";
import { CursoCard } from "../../components/CursoCard";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import { useApi } from "../../hooks/useApi";
import { SearchOutlined, StarFilled } from "@ant-design/icons";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./styles.module.css";
import { Autoplay, Pagination, Navigation } from "swiper";

type Curso = {
  id: string;
  nome: string;
  descricao: string;
  rate: string;
  capa: string;
};

export const VideoAula = () => {
  const api = useApi();
  const auth = useContext(AuthContext);
  const [cards, setCards] = useState([
    {
      id: "",
      nome: "",
      descricao: "",
      capa: "",
      rate: "",
      categorias_nome: "",
    },
  ]);
  const [curso, setCurso] = useState<Curso | null>(null);
  const [openAula, setOpenAula] = useState(false);
  const [cursoSelect, setCursoSelect] = useState("");
  const [cursoTitulo, setCursoTitulo] = useState("");
  const [loading, setLoading] = useState(false);
  const [messageLoad, setMessageLoad] = useState("Carregando...");
  const [searchNome, setSearchNome] = useState("");
  const [searchCategoria, setSearchCategoria] = useState("");
  const [categorias, setCategorias] = useState([]);
  const [qtdCard, setQtdCard] = useState(1);

  const returnAula = () => {};

  const closeModalAula = () => {
    setOpenAula(false);
    setCurso(null);
  };

  const openModalAula = (curso_id: string, nome: string) => {
    setCursoTitulo(nome);
    setCursoSelect(curso_id);
    setOpenAula(true);
  };

  const getCursos = async (categoria: string) => {
    setMessageLoad("Buscando os cursos, aguarde...");
    setLoading(true);
    await api.getCursos(searchNome, categoria).then((res) => {
      const list: any = [];
      if (res) {
        res.forEach(
          (value: {
            id: string;
            nome: string;
            descricao: string;
            rate: number;
            capa: string;
            categorias_nome: string;
          }) => {
            list.push({
              id: value.id,
              nome: value.nome,
              descricao: value.descricao,
              rate: Number(value.rate),
              capa: value.capa,
              categorias_nome: value.categorias_nome,
            });
          }
        );
        setCards(list);
      } else {
        setCards([]);
      }
    });
    setMessageLoad("");
    setLoading(false);
  };

  const getCategorias = async () => {
    await api.comboCategoriaCurso().then((res) => {
      if (res) {
        const cat: any = [];
        res.map((item: any) => {
          cat.push({ value: item.id, label: item.nome });
        });
        setCategorias(cat);
      }
    });
  };

  useEffect(() => {
    getCursos("");
    getCategorias();
    setQtdCard(Math.floor(window.screen.width / 240));
  }, []);

  return (
    <>
      <Col style={{ width: "100%", height: "100%" }}>
        {auth.cotas != null ? (
          <>
            <Spin tip={messageLoad} spinning={loading}>
              <Row style={{ width: "100%" }}>
                <Col>
                  <div style={{ padding: 5 }}>
                    <Button
                      style={{
                        backgroundColor: "#ffe609",
                        border: "1px dashed #111111",
                        color: "#111111",
                      }}
                      onClick={() => {
                        //setSearchCategoria(categoria.value);
                        getCursos("");
                      }}
                    >
                      <StarFilled />
                      {"Novidades"}
                    </Button>
                  </div>
                </Col>
                <Col>
                  <Row style={{ width: "100%", overflowX: "hidden" }}>
                    {categorias.map((categoria: any, index) => {
                      return (
                        <div style={{ padding: 5 }}>
                          <Button
                            key={index}
                            style={{
                              backgroundColor: "#1e1d1d",
                              border: "1px dashed #727272",
                              color: "#ffffff",
                            }}
                            onClick={() => {
                              //setSearchCategoria(categoria.value);
                              getCursos(categoria.value);
                            }}
                          >
                            {categoria.label}
                          </Button>
                        </div>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
              <div className={styles.container}>
                <Swiper
                  slidesPerView={qtdCard} //{screenW < mini ? 1 : 5}
                  className={styles.swiper}
                  spaceBetween={5}
                  centeredSlides={false}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: true,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  modules={[Autoplay, Pagination, Navigation]}
                >
                  <>
                    {cards.length > 0 ? (
                      cards.map((item, index) => {
                        return (
                          <SwiperSlide className={styles.slide} key={index}>
                            <div
                              onClick={() => openModalAula(item.id, item.nome)}
                            >
                              <CursoCard
                                id={item.id}
                                nome={item.nome}
                                descricao={item.descricao}
                                capa={item.capa}
                                rate={Number(item.rate)}
                                categorias_nome={item.categorias_nome}
                              />
                            </div>
                          </SwiperSlide>
                        );
                      })
                    ) : (
                      <Empty
                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                        imageStyle={{
                          marginTop: 80,
                          height: 60,
                        }}
                        description={
                          <span style={{ color: "#ffffff" }}>
                            Desculpe, mas ainda não temos cursos cadastrados
                            para essa categoria!
                          </span>
                        }
                      ></Empty>
                    )}
                  </>
                </Swiper>
              </div>
            </Spin>
            <Aula
              title={cursoTitulo}
              curso={cursoSelect}
              onChange={() => {}}
              onClose={closeModalAula}
              open={openAula}
            />
          </>
        ) : (
          <Row>
            Precisamos que seja adquirida pelo menos uma cota. Se já fez a sua
            aquisição e ainda não pagou, vá até a área financeira e em pedidos e
            efetue o pagamento do pedido pendente ou faça outro pedido.
          </Row>
        )}
        {/* <div style={{ paddingInline: 20, fontSize: 28, color: '#fcfcfc', backgroundColor: '#0000009e', borderRadius: 10 }}>Minha{cards.length > 1 ? 's' : ''} Cota{cards.length > 1 ? 's' : ''}</div> */}
        {/*auth.cotas != null ? (
          <>
            <Spin tip={messageLoad} spinning={loading}>
              <Row
                style={{
                  width: "99%",
                  display: "flex",
                  justifyContent: "center",
                  padding: 10,
                  overflowX: "scroll",
                  height: "30vh",
                }}
              >
                {cards.length > 0 ? (
                  cards.map((item) => {
                    return (
                      <Col>
                        <div style={{ padding: 5 }}>
                          <a onClick={() => openModalAula(item.id, item.nome)}>
                            <Tooltip title={item.descricao}>
                              <Card
                                // title={<div style={{ fontSize: 12, display: 'flex' }}>{item.nome}</div>}
                                hoverable
                                style={{
                                  paddingInline: 5,
                                  width: 200,
                                  height: 300,
                                  borderRadius: 5,
                                  display: "flex",
                                  // flexDirection: 'column',
                                  justifyContent: "center",
                                  boxShadow: "2px 2px 5px #656565",
                                  backgroundColor: "#ffffff3b",
                                  border: "1px solid #ffffff10",
                                  color: "#ffffff",
                                }}
                              >
                                <Row
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100%",
                                    height: 50,
                                    textAlign: "center",
                                    paddingTop: 0,
                                    marginBottom: -15,
                                  }}
                                >
                                  {item.nome}
                                </Row>
                                <Divider style={{ marginBottom: 2.5 }} />
                                <Row
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <img
                                    style={{ borderRadius: 10, width: 120 }}
                                    alt={item.nome}
                                    src={item.capa}
                                  />
                                </Row>
                                <Row
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    marginBottom: 0,
                                  }}
                                >
                                  <Rate
                                    style={{ fontSize: 12 }}
                                    disabled
                                    defaultValue={
                                      item.rate == "0"
                                        ? Math.random() * 5
                                        : parseInt(item.rate)
                                    }
                                  />
                                </Row>
                                <Row
                                  style={{
                                    width: "100%",
                                    fontSize: 10,
                                    paddingTop: 5,
                                  }}
                                >
                                  {item.categorias_nome}
                                </Row>
                              </Card>
                            </Tooltip>
                          </a>
                        </div>
                      </Col>
                    );
                  })
                ) : (
                  <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{
                      height: 60,
                    }}
                    style={{
                      color: "#fcfcfc",
                      backgroundColor: "#0000009e",
                      width: "80vw",
                      height: "60vh",
                      borderRadius: 5,
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                    description={
                      <span>
                        Desculpe, mas ainda não temos cursos cadastrados!
                      </span>
                    }
                  ></Empty>
                )}
              </Row>
            </Spin>
            <Aula
              title={cursoTitulo}
              curso={cursoSelect}
              onChange={() => {}}
              onClose={closeModalAula}
              open={openAula}
            />
          </>
        ) : (
          <Row>
            Precisamos que seja adquirida pelo menos uma cota. Se já fez a sua
            aquisição e ainda não pagou, vá até a área financeira e em pedidos e
            efetue o pagamento do pedido pendente ou faça outro pedido.
          </Row>
        )*/}
      </Col>
    </>
  );
};
