import {
  CloseCircleFilled,
  CopyOutlined,
  DeleteOutlined,
  DollarOutlined,
  FolderOpenOutlined,
  QuestionCircleOutlined,
  UserOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  notification,
  Popconfirm,
  Progress,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
} from "antd";
import { MaskedInput } from "antd-mask-input";
import { InputMask } from "imask";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactWhatsapp from "react-whatsapp";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import { useApi } from "../../hooks/useApi";
import { OrdemPagamento } from "../OrdemPagamento";
import styles from "./styles.module.css";

const { Panel } = Collapse;

type Props = {
  closeCota: () => void;
};

export const EstruturaCota = ({ closeCota }: Props) => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const apiEstrutura = useApi();
  const [formOrdem] = Form.useForm();
  const [data, setData] = useState([]);
  const [phoneEnvite, setPhoneEnvite] = useState("");
  const [openOrdem, setOpenOrdem] = useState(false);
  const [valorOrdem, setValorOrdem] = useState<number | null>(
    parseFloat(auth.cota?.saldo!)
  );
  const [load, setLoad] = useState(false);
  const [messageLoad, setMessageLoad] = useState("");
  const [loadSaque, setLoadSaque] = useState(false);
  const [messageLoadSaque, setMessageLoadSaque] = useState("");

  const onChange = (tel: string) => {
    setPhoneEnvite(tel);
  };

  const getEstrutura = async () => {
    setData([]);
    setMessageLoad(
      "Buscando estrutura, essa ação pode demorar um pouco. Aguarde..."
    );
    setLoad(true);
    await apiEstrutura
      .getStruct(auth.cota!.id)
      .then((res) => {
        setData(res);
        setMessageLoad("");
        setLoad(false);
      })
      .catch((err) => {
        notification.error({
          message: "Tivemos problemas para solicitar a estrutura.",
          description:
            "Algo inesperado ocorreu com esta ação. Por favor refaça a ação.",
        });
        setMessageLoad("");
        setLoad(false);
      });
  };

  /*const onChangeValorOrdem = (value: string | null) => {
    if (value) {
      setValorOrdem(parseFloat(value.toFixed(2)));
    }
  };*/

  const onRegisterPayment = async () => {
    const values: any = formOrdem.getFieldsValue();
    if (values.tipo_chave_pix != null) {
      if (parseInt(values.valor.replace(",", ".")) >= 10.0) {
        setLoadSaque(true);
        setMessageLoadSaque("Registrando sua solicitação. Aguarde...");
        //values.valor = values.valor;
        values.cota_id = auth.cota?.id!;
        await apiEstrutura
          .registerPayment(values)
          .then((res) => {
            setLoadSaque(false);
            setMessageLoadSaque("");
            notification.success({ message: res.message });
            fecharEstrutura();
            setData([]);
            closeCota();
          })
          .catch((err) => {
            notification.error({
              message: "Problemas para processar sua solicitação.",
              description:
                "Algum erro está impedindo esta ação, por favor refaça mais tarde, caso não resolva entre em contato com o suporte.",
            });
            setLoadSaque(false);
            setMessageLoadSaque("");
          });
      } else {
        notification.info({
          message: "Opa, temos um problema.",
          description:
            "O valor informado é inferior a R$ 10,00 (dez reais). Pedimos que refaça o seu pedido ou acumule mais cashbacks para efetuar seu saque.",
        });
        formOrdem.setFieldValue("valor", "10,00");
      }
    } else {
      notification.error({
        message: "Não esqueceu de nada não?",
        description: "Preciso que informe a chave para fazer o pix.",
      });
    }
  };

  const fecharEstrutura = () => {
    closeCota();
  };

  useEffect(() => {
    if (auth.cota != null) {
      getEstrutura();
    }
    formOrdem.resetFields();
  }, [auth.cota]);

  return (
    <div className={styles.container}>
      {/* <DecompositionTreeGraph {...config} /> */}
      <Row
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
        }}
      >
        <Col span={12} style={{ paddingInline: 3, width: "100%" }}>
          <Card
            title={"Dados do convite"}
            // extra={<a href='#' onClick={() => fecharEstrutura()}><CloseCircleFilled style={{ color: '#ff0000' }} /></a>}
          >
            <Row className={styles.rowLevel}>
              <Col>
                <Row style={{ width: "100%", fontSize: 18 }}>
                  <Space>
                    Convite:&nbsp;&nbsp;&nbsp;{auth.cota?.codigo_unico}
                  </Space>
                </Row>
                <Row style={{ width: "100%", fontSize: 18 }}>
                  Saldo:&nbsp;&nbsp;&nbsp;
                  <strong>
                    {auth.cota
                      ? new Intl.NumberFormat("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        }).format(+auth.cota?.saldo!)
                      : null}
                  </strong>
                </Row>
              </Col>
              <Divider>Convidar</Divider>
              <Col>
                <Row
                  style={{
                    width: "100%",
                    paddingBottom: 10,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Codigo Convite&nbsp;
                  <Tooltip title="Você pode convidar amigos pedindo para se cadastrarem com o seu código convite. Este codigo distribuirá seus convidados cadastrados, que adquirirem um convite, abaixo deste convite.">
                    <QuestionCircleOutlined />
                  </Tooltip>
                  :&nbsp;&nbsp;&nbsp;<strong>{auth.cota?.codigo_unico}</strong>
                  &nbsp;
                  <Tooltip title="Clique para copiar o código para a área de transferência!">
                    <Button
                      onClick={() => {
                        navigator.clipboard
                          .writeText(auth.cota?.codigo_unico!)
                          .then(() => {
                            notification.open({
                              message: "Código copiado",
                              description:
                                "Código convite " +
                                auth.cota?.codigo_unico +
                                " copiado para área de transferência.",
                            });
                          })
                          .catch(() => {
                            notification.error({
                              message: "Erro ao copiar código!",
                            });
                          });
                      }}
                    >
                      <CopyOutlined />
                    </Button>
                  </Tooltip>
                </Row>
                <Row>
                  <MaskedInput
                    mask={"(00) 00000-0000"}
                    style={{ width: 150 }}
                    defaultValue={phoneEnvite}
                    onChange={(e) => {
                      setPhoneEnvite(e.target.value);
                    }}
                    required
                  />
                  <ReactWhatsapp
                    message={`E aí, tudo bom? Queria te convidar pra um clube super legal que eu tô participando. É só acessar https://unlocke.com.br/oportunidade/${auth.cota?.codigo_unico} e usar o código ${auth.cota?.codigo_unico} pra se cadastrar. Se precisar de mais informações, é só falar comigo!`}
                    number={`+55${phoneEnvite}`}
                    element="a"
                  >
                    <Button
                      style={{
                        backgroundColor: "#34a500",
                        color: "#fff",
                        width: 100,
                      }}
                    >
                      <WhatsAppOutlined /> Convidar
                    </Button>
                  </ReactWhatsapp>
                </Row>
              </Col>
            </Row>
            <Divider>Solicitar Saques</Divider>
            <Spin
              spinning={loadSaque}
              tip={messageLoadSaque}
              style={{ width: "100%" }}
            >
              <Row style={{ width: "100%" }}>
                {auth.cota != null ? (
                  parseInt(auth.cota!.saldo!) > parseInt("10.00") ? ( //mudar para 10 reais
                    <Row style={{ width: "100%" }}>
                      <Form
                        form={formOrdem}
                        layout={"vertical"}
                        style={{ width: "100%" }}
                      >
                        <Form.Item name={"pessoa_id"} hidden>
                          <Input />
                        </Form.Item>
                        <Form.Item name={"cota_id"} hidden>
                          <Input defaultValue={auth.cota?.id} />
                        </Form.Item>
                        <Row>
                          <Col style={{ paddingInline: 5 }} span={12}>
                            <Form.Item
                              label={"Chave Pix"}
                              name={"tipo_chave_pix"}
                              rules={[{ required: true }]}
                            >
                              <Select
                                options={[
                                  {
                                    value: `Cpf:${auth.user?.cpf}`,
                                    label: `CPF: ${auth.user?.cpf}`,
                                  },
                                  {
                                    value: `Email:${auth.user?.email}`,
                                    label: `EMAIL: ${auth.user?.email}`,
                                  },
                                  {
                                    value: `Celular:${auth.user?.celular}`,
                                    label: `CELULAR: ${auth.user?.celular}`,
                                  },
                                ]}
                              />
                            </Form.Item>
                          </Col>
                          <Col style={{ paddingInline: 5 }} span={6}>
                            <Tooltip title={"obs"}>
                              <Form.Item
                                label={"Valor"}
                                name={"valor"}
                                initialValue={new Intl.NumberFormat("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                }).format(+auth.cota?.saldo!)}
                              >
                                <Input
                                  prefix={"R$"}
                                  onInput={(e) =>
                                    ((e.target as HTMLInputElement).value = (
                                      e.target as HTMLInputElement
                                    ).value.replace(/[^0-9,]/g, ""))
                                  }
                                />
                                {/* <InputNumber
                                  style={{ width: 150 }}
                                  defaultValue={valorOrdem!}
                                  max={parseInt(auth.cota!.saldo!)}
                                  min={1.0} //Mudar para 10 reais
                                  formatter={(value) =>
                                    `R\$ ${value}`.replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )
                                  }
                                  parser={(value) =>
                                    parseFloat(
                                      value!.replace(/\$\s?|(,*)/g, "")
                                    )
                                  }
                                  onChange={onChangeValorOrdem}
                                /> */}
                              </Form.Item>
                            </Tooltip>
                          </Col>
                        </Row>
                        <Row
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            marginTop: -10,
                          }}
                        >
                          <Form.Item>
                            <Popconfirm
                              title={`Taxa da solicitação`}
                              description={`Você está fazendo uma solicitação de PIX, assim que recebermos vamos proceder a execução. Pode ser que haja várias requisições antes da sua por isso pedimos que aguarde. Essa ação pode demorar até 3 dias úteis. Deseja continuar com a solicitação?`}
                              onConfirm={(e) => onRegisterPayment()}
                              onCancel={(e) => {
                                message.info(
                                  "Solicitação cancelada pelo usuário."
                                );
                              }}
                              okText={"Sim"}
                              cancelText={"Não"}
                            >
                              <Button
                                htmlType="submit"
                                style={{
                                  backgroundColor: "#216800",
                                  color: "#fff",
                                }}
                                icon={<DollarOutlined />}
                              >
                                Solicitar PIX
                              </Button>
                            </Popconfirm>
                          </Form.Item>
                        </Row>
                      </Form>
                    </Row>
                  ) : (
                    <Row style={{ marginTop: 20 }}>
                      <span>Saldo insuficiente para saque.</span>&nbsp;
                      <Tooltip title="Para conseguir fazer o saque do seu convite é preciso que o saldo seja superior ou igual a R$10,00 (dez reais).">
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </Row>
                  )
                ) : (
                  <Row style={{ marginTop: 20 }}>
                    <span>Saldo insuficiente para saque.</span>&nbsp;
                    <Tooltip title="Para conseguir fazer o saque do seu convite é preciso que o saldo seja superior ou igual a R$10,00 (dez reais).">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </Row>
                )}
              </Row>
            </Spin>
          </Card>
        </Col>
        <Col span={12} style={{ width: "100%" }}>
          <Spin tip={messageLoad} spinning={load}>
            {/* <Collapse>
                            <Panel key={'1'} header={'Níveis do sucesso'}> */}
            <Card
            // extra={<a href='#' onClick={() => fecharEstrutura()}><CloseCircleFilled style={{ color: '#ff0000' }} /></a>}
            >
              {data.map((cota, index) => {
                if (index > 0) {
                  return (
                    <Card>
                      <Row className={styles.rowLevel}>
                        <Col span={6}>
                          {index}: {auth.nivel![index - 1]}
                        </Col>
                        <Col span={10}>
                          <UserOutlined
                            style={{
                              color:
                                data[index]["restante"] == 0
                                  ? "green"
                                  : data[index]["limite"] ==
                                    data[index]["restante"]
                                  ? "red"
                                  : "yellow",
                            }}
                          />
                          &nbsp;&nbsp;
                          {data[index]["restante"] == 0
                            ? `Nível concluído.`
                            : data[index]["limite"] == data[index]["restante"]
                            ? `Nível não iniciado.`
                            : `Nível em andamento.`}
                        </Col>
                        <Col span={8}>
                          <Progress
                            percent={parseInt(
                              (
                                (data[index]["total"] / data[index]["limite"]) *
                                100
                              ).toFixed(2)
                            )}
                          />
                        </Col>
                      </Row>
                    </Card>
                  );
                }
              })}
            </Card>
            {/* </Panel>
                        </Collapse>               */}
          </Spin>
        </Col>
      </Row>
    </div>
  );
};
