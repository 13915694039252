import {
  AppstoreOutlined,
  AppstoreTwoTone,
  BackwardFilled,
  BankOutlined,
  BankTwoTone,
  RocketOutlined,
  RocketTwoTone,
  SettingOutlined,
  SettingTwoTone,
  TagsOutlined,
  TagsTwoTone,
} from "@ant-design/icons";
import { Menu, MenuProps, MenuTheme } from "antd";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import { useApi } from "../../hooks/useApi";
import "./styles.modules.css";

type Props = {
  font: string;
  onChange: (value: string) => void;
  collapsed: boolean;
  theme: MenuTheme;
};

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}
export const MenuCustom = ({
  collapsed = false,
  font,
  onChange,
  theme,
}: Props) => {
  const [items, setItems] = useState<MenuProps["items"]>();
  const api = useApi();
  const auth = useContext(AuthContext);

  const getMenu = async () => {
    //get Menu
    const data = await api.getMenuDashboard(font);
    let newItems: any = [];
    data.forEach((value: any) => {
      const menus = value.menus;
      const newLine: any = [];
      if (menus.length > 0) {
        menus.forEach((line: any) => {
          newLine.push(getItem(line.nome, `${line.route}`));
        });
        let icon = <AppstoreOutlined />;
        switch (value.nome) {
          case "Administração":
            icon = <SettingOutlined />;
            break;
          case "Cotas":
            icon = <TagsOutlined />;
            break;
          case "Cursos":
            icon = <RocketOutlined />;
            break;
          case "Financeiro":
            icon = <BankOutlined />;
            break;

          default:
            icon = <BackwardFilled />;
            break;
        }

        /*value.nome == 'Cursos' ? 
          auth.cotas != null ?? 
            newItems.push(getItem(value.nome, `${value.route}`, icon, newLine)):*/
        newItems.push(getItem(value.nome, `${value.route}`, icon, newLine));
      }
    });
    const arr: MenuProps["items"] = newItems;
    setItems(arr);
  };

  useEffect(() => {
    getMenu();
  }, [0]);

  const onClick: MenuProps["onClick"] = (e) => {
    onChange(e.key);
  };

  return (
    <>
      {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20, padding: '5px 11px 5px 11px', height: 40, width: '100%', backgroundColor: "#476c39", color: '#fff', borderRadius: '10px 10px 0 0', borderBottom: '1px dashed #0B0B3B', fontWeight: 700, boxShadow: '5px 5px 12px #2E2E2E', }}>UnLocke</div> */}
      <Menu
        onClick={onClick}
        // defaultSelectedKeys={['1']}
        mode="inline"
        direction="ltr"
        //inlineCollapsed={collapsed}
        items={items}
        theme={theme}
        //style={{ backgroundColor: '#ffffffac', fontWeight: 500, color: '#1a1a1a', boxShadow: '3px 3px 6px #5e5e5e', borderRadius: '10px', paddingBottom: 0, marginTop: 10, width: 'auto'/*, height: '80vh'*/ }}
      />
    </>
  );
};
