import { DeleteFilled, EditFilled, SubnodeOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Divider, Form, Input, notification, Row, Space, Spin, Table } from "antd"
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useApi } from "../../hooks/useApi";

interface RecordType {
    key: string;
    title: string;
}

interface OrdemDataType {
    key: string;
    nome: string;
    tipo_pix: string;
    pix: string;
    valor: string;
    status: string;
}

export const ControlePagamento = () => {

    const api = useApi();
    const [ordemDS, setOrdemDS] = useState<OrdemDataType[]>();
    const [load, setLoad] = useState(false);
    const [messageLoad, setMessageLoad] = useState('');
    const [form] = Form.useForm();
    const dateFormat = 'DD/MM/YYYY'

    const ordem = {
        key: String,
        nome: String,
        tipo_pix: String,
        pix: String,
        valor: String,
        status: String,
    }

    //Lista
    const columns: ColumnsType<OrdemDataType> = [
        {
            title: 'Nome',
            dataIndex: 'nome',
            key: 'nome',
            /*render: (_, value) => {
                if(value.parent_desc){
                    return <>&nbsp;&nbsp;&nbsp;&nbsp;<SubnodeOutlined />&nbsp;&nbsp;{value.name}</>
                }
                return value.nome
            }*/
        },
        {
            title: 'Tipo Pix',
            dataIndex: 'tipo_pix',
            key: 'tipo_pix',
        },
        {
            title: 'Pix',
            dataIndex: 'pix',
            key: 'pix',
        },
        {
            title: 'Valor',
            dataIndex: 'valor',
            key: 'valor',
        },
        {
            title: 'Situação',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Ações',
            key: 'action',
            render: (_, record) => {
                if(record.status == 'Pendente'){
                    return  (
                        <>
                            <Space size="middle">
                                <Button onClick={() => openEdit(record)}><EditFilled /></Button> 
                                <Button danger ><DeleteFilled /></Button>
                            </Space>
                        </>
                    )
                }else{
                    return (<></>)
                }
                
            }
        }
    ];

    const getOrdensAll = async () => {
        setMessageLoad('Buscando as ordens de pagamento.');
        setLoad(true);
        await api.getOrdersAll().then((res) => {
            if(res){
                let ordemFim: any = [];
                res.map((item: any) => {
                    let ordemRes: any = [];
                    ordemRes.key = item.id;
                    ordemRes.nome = item.nome;
                    ordemRes.tipo_pix = item.tipo_chave_pix;
                    ordemRes.pix = item.chave_pix;
                    ordemRes.valor = item.valor;
                    ordemRes.status = item.status;
                    ordemFim.push(ordemRes);
                });
                setOrdemDS(ordemFim);
            }
            setMessageLoad('');
            setLoad(false);
        });
    }

    const openEdit = (ordem: any) => {
        form.setFieldValue('id', ordem.key);
        form.setFieldValue('nome', ordem.nome);
        form.setFieldValue('tipo_pix', ordem.tipo_pix);
        form.setFieldValue('pix', ordem.pix);
        form.setFieldValue('valor', ordem.valor);
    }

    const onFinish = async (values: any) => {
        if(values.codigo_transacao == null){
            notification.error({message: 'O código da transação é obrigatório'});
        }else{
            await api.executarOrdem(values).then((res) => {
                if(res){
                    notification.info({message: 'Ordem executada.'});
                    getOrdensAll();
                    form.resetFields();
                }                
            });
        }
    }

    useEffect(() => {
        getOrdensAll();
    }, []);

    return (
        <>
            <Row style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: 10, color: '#fff', fontSize: 18, fontFamily: 'Roboto', fontWeight: 300 }}>BAIXA DE PEDIDOS DE PAGAMENTOS</Row>
            <Divider style={{ margin: 5 }} />
            <Row style={{ backgroundColor: '#ffffff84', padding: 10, borderRadius: 8 }}>
                <Form 
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row>
                        <Col style={{ paddingLeft: 2.5}} >
                            <Form.Item hidden name={'id'}>
                                <Input readOnly />
                            </Form.Item>
                            <Form.Item name={'nome'} label={'Favorecido'}>
                                <Input readOnly />
                            </Form.Item>
                        </Col>
                        <Col span={3} style={{ paddingLeft: 2.5}} >
                            <Form.Item name={'tipo_pix'} label={'Tipo Pix'}>
                                <Input readOnly />
                            </Form.Item>
                        </Col>
                        <Col span={4} style={{ paddingLeft: 2.5}} >
                            <Form.Item name={'pix'} label={'Pix'}>
                                <Input readOnly />
                            </Form.Item>
                        </Col>
                        <Col span={3} style={{ paddingLeft: 2.5}} >
                            <Form.Item name={'valor'} label={'Valor'}>
                                <Input readOnly />
                            </Form.Item>
                        </Col>
                        <Col span={4} style={{ paddingLeft: 2.5}} >
                            <Form.Item name={'codigo_transacao'} label={'Codigo Pag.'} rules={[{required: true, message: 'O código de pagamento é obrigatório.'}]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={4} style={{ paddingLeft: 2.5}} >
                            <Form.Item name={'pagamento'} label={'Data Pagamento'}>
                                <DatePicker format={dateFormat}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ display: 'flex' }}>
                        <Button htmlType="submit">Gravar</Button>
                    </Row>
                </Form>
            </Row>
            <div style={{ maxHeight: '80vh', overflow: 'auto', paddingTop: 30, paddingInline: 10, width: '100%'}}>
                <Spin spinning={load} tip={messageLoad}> 
                    <Table columns={columns} dataSource={ordemDS} style={{ overflowX: 'auto', height: '60vh' }}/>
                </Spin>
            </div>
        </>
    )
}