import {
  Button,
  Card,
  Checkbox,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Popover,
  Row,
  Space,
} from "antd";
import { MaskedInput } from "antd-mask-input";
import { useContext, useEffect, useState } from "react";
import ReactPasswordChecklist from "react-password-checklist";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import { useApi } from "../../hooks/useApi";
import { Privacidade } from "../Privacidade";
import { TermoUso } from "../TermoUso";

export const CadCliente = () => {
  const auth = useContext(AuthContext);
  const api = useApi();
  const [password, setPassword] = useState("");
  const [passwordAtual, setPasswordAtual] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [senhaValida, setSenhaValida] = useState(false);
  const [isModalOpenTermoUso, setIsModalOpenTermoUso] = useState(false);
  const [isModalOpenPrivacidade, setIsModalOpenPrivacidade] = useState(false);

  const showModalTermoUso = () => {
    setIsModalOpenTermoUso(true);
  };

  const handleOkTermoUso = () => {
    setIsModalOpenTermoUso(false);
  };

  const handleCancelTermoUso = () => {
    setIsModalOpenTermoUso(false);
  };

  const showModalPrivacidade = () => {
    setIsModalOpenPrivacidade(true);
  };

  const handleOkPrivacidade = () => {
    setIsModalOpenPrivacidade(false);
  };

  const handleCancelPrivacidade = () => {
    setIsModalOpenPrivacidade(false);
  };

  const onLoadData = () => {};

  const onFinish = async (values: any) => {
    values.passwordold = passwordAtual;
    values.passwordnew = password;
    values.c_password = passwordAgain;
    console.log(values);
    await api.updatePersona(values).then((res: any) => {
      const data = res.data;
      if (data.status == "Success") {
        notification.success({ message: data.message });
      } else {
        notification.error({ message: data.message });
      }
    });
  };

  const ListValidPasswordAtual = (
    <div>
      <ReactPasswordChecklist
        rules={["minLength", "specialChar", "number", "capital", "lowercase"]}
        minLength={8}
        value={password}
        valueAgain={passwordAgain}
        onChange={(isValid) => {
          setSenhaValida(isValid);
        }}
        messages={{
          minLength: "A senha deve ter no mínimo 8 caracteres.",
          specialChar: "A senha deve ter um caracter especial.",
          number: "A senha deve ter um número",
          capital: "A senha deve ter uma letra maiúscula.",
          lowercase: "A senha deve ter uma letra minúscula.",
          match: "As senhas devem ser iguais.",
        }}
      />
    </div>
  );

  const ListValidPassword = (
    <div>
      <ReactPasswordChecklist
        rules={[
          "minLength",
          "specialChar",
          "number",
          "capital",
          "lowercase",
          "match",
        ]}
        minLength={8}
        value={password}
        valueAgain={passwordAgain}
        onChange={(isValid) => {
          setSenhaValida(isValid);
        }}
        messages={{
          minLength: "A senha deve ter no mínimo 8 caracteres.",
          specialChar: "A senha deve ter um caracter especial.",
          number: "A senha deve ter um número",
          capital: "A senha deve ter uma letra maiúscula.",
          lowercase: "A senha deve ter uma letra minúscula.",
          match: "As senhas devem ser iguais.",
        }}
      />
    </div>
  );

  useEffect(() => {
    setPasswordAtual("");
  }, []);

  return (
    <>
      <Form
        name="formCadastro"
        onFinish={onFinish}
        labelCol={{ span: 8 }}
        style={{ width: "100%", paddingTop: 10 }}
      >
        <Form.Item hidden name={"id_filiacao"} initialValue={auth.filiacao?.id}>
          <Input />
        </Form.Item>
        <Form.Item
          style={{ marginTop: -5 }}
          label={"Nome"}
          name={"nome"}
          initialValue={auth.user?.name}
          rules={[{ required: true, message: "Nome é obrigatório!" }]}
        >
          <Input readOnly disabled />
        </Form.Item>

        <Form.Item
          style={{ marginTop: -15 }}
          label={"Cpf"}
          name={"cpf"}
          initialValue={auth.user?.cpf}
          rules={[{ required: true, message: "Cpf é obrigatório!" }]}
        >
          <MaskedInput
            placeholder={"CPF do usuário"}
            id="cpf"
            mask={"000.000.000-00"}
            required={true}
            style={{ width: 200 }}
            readOnly={true}
            disabled
          />
        </Form.Item>

        {/* <Form.Item style={{ marginTop: -10 }} label={'Sexo'} name={'sexo'} rules={[{required: true}]}>
                        <Select
                            options={[
                                {value: 'Masculino', label: 'Masculino'},
                                {value: 'Feminino', label: 'Feminino'}
                            ]}
                            style={{ width: 200 }}
                        />
                    </Form.Item> */}

        <Form.Item
          style={{ marginTop: -15 }}
          label={"Email"}
          name={"email"}
          initialValue={auth.user?.email}
          rules={[
            {
              required: true,
              type: "email",
              message: "Email é obrigatório!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          style={{ marginTop: -15 }}
          label={"Celular"}
          name={"celular"}
          initialValue={auth.user?.celular}
          rules={[
            { required: true, message: "Númedo de celular é obrigatório!" },
          ]}
        >
          <MaskedInput
            placeholder={"Celular do usuário"}
            id="celular"
            mask={"(00) 00000-0000"}
            required={true}
            style={{ width: 200 }}
          />
        </Form.Item>

        {/* <Form.Item
          style={{ marginTop: -15 }}
          label={"Senha Atual"}
          name={"passwordold"}
          rules={[{ required: true, message: "Informe a senha atual." }]}
        >
          <Input />
        </Form.Item> */}

        <Form.Item
          name="passwordold"
          label="Senha Atual"
          style={{ marginTop: -15 }}
          rules={[
            {
              required: true,
              message:
                "Preciso que informe a senha atual para confirmar as alterações.",
            },
          ]}
        >
          <Input.Password
            style={{ width: 200 }}
            onChange={(e) => setPasswordAtual(e.target.value)}
            value={passwordAtual}
            defaultValue={passwordAtual}
          />
        </Form.Item>

        <Form.Item
          name="passwordnew"
          label="Nova Senha"
          hasFeedback
          style={{ marginTop: -15 }}
        >
          <Popover
            placement="right"
            content={ListValidPassword}
            title={"Validação"}
          >
            <Input.Password
              style={{ width: 200 }}
              onFocus={(e) => ListValidPassword}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Popover>
        </Form.Item>

        <Form.Item
          style={{ marginTop: -15 }}
          name="c_password"
          label="Confirme senha"
          dependencies={["password"]}
          hasFeedback
        >
          <Popover
            placement="right"
            content={ListValidPassword}
            title={"Validação"}
          >
            <Input.Password
              style={{ width: 200 }}
              onFocus={(e) => ListValidPassword}
              onChange={(e) => setPasswordAgain(e.target.value)}
            />
          </Popover>
        </Form.Item>

        <Divider></Divider>
        <Form.Item wrapperCol={{ offset: 8 }} style={{ marginTop: -15 }}>
          <Button type="primary" htmlType="submit">
            Atualizar Informações
          </Button>
        </Form.Item>
      </Form>
      <Row
        style={{
          width: "100%",
          marginTop: -10,
          paddingBottom: 10,
          paddingLeft: 30,
        }}
      >
        <Space>
          <a href="#" onClick={showModalTermoUso}>
            Termo de uso
          </a>{" "}
          <a href="#" onClick={showModalPrivacidade}>
            Política de Privacidade
          </a>
        </Space>
      </Row>
      <Modal
        footer
        open={isModalOpenTermoUso}
        onOk={handleOkTermoUso}
        onCancel={handleCancelTermoUso}
      >
        <TermoUso />
      </Modal>
      <Modal
        footer
        open={isModalOpenPrivacidade}
        onOk={handleOkPrivacidade}
        onCancel={handleCancelPrivacidade}
      >
        <Privacidade />
      </Modal>
    </>
  );
};
